// src/components/ThemeSwitcher.js
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSun, faMoon } from '@fortawesome/free-solid-svg-icons';
import { toggleTheme } from '../store'; 
import './ThemeSwitcher.css';

const ThemeSwitcher = () => {
    const dispatch = useDispatch();
    const theme = useSelector((state) => state.theme);

    const handleToggle = () => {
        dispatch(toggleTheme());

    };

    return (
        <button className="theme-switcher-button" onClick={handleToggle}>
            <FontAwesomeIcon icon={theme === 'light' ? faMoon : faSun} className="theme-switcher-icon" />
        </button>
    );
};

export default ThemeSwitcher;
