// src/services/emailService.js
import io from 'socket.io-client';

let socketRef = null;
let abortControllerRef = null;

export const initializeSocket = (url, setEmails, setProgress, handleEmailUpdate, mailboxType) => {
    let socketUrl;

    if (url.startsWith('https://')) {
        socketUrl = url.replace('https://', 'wss://');
    } else if (url.startsWith('http://')) {
        socketUrl = url.replace('http://', 'ws://');
    } else {
        socketUrl = url;
    }

    const socket = io(socketUrl);
    socketRef = socket;

    socket.on('connect', () => {

        // 根据邮箱类型订阅
        socket.emit('subscribe', mailboxType);
    });

    socket.on('emails', (initialEmails) => {
        //console.log('Initial emails received:', initialEmails);
        setEmails(initialEmails);
    });

    socket.on('emailUpdated', (updatedEmail) => {
        //console.log('Email update received:', updatedEmail);
        handleEmailUpdate(updatedEmail);
    });

    socket.on('progress', (newProgress) => {
        setProgress(newProgress);
    });

    return socket;
};


export const disconnectSocket = () => {
    if (socketRef) {
        socketRef.disconnect();
    }
};

export const fetchEmailsFromSelectedFolder = async (
    url,
    folder,
    mailboxType,
    skipValue = 0,
    setLoading,
    setEmails,
    setSkip,
    setHasMoreEmails,
    setIsLoadingMoreEmails,
    setIsFolderDisabled
) => {
    if (!folder) {
        return;
    }

    setLoading(true);
    setIsLoadingMoreEmails(true);
    if (skipValue === 0) {
        setIsFolderDisabled(true);
    }

    if (abortControllerRef) {
        abortControllerRef.abort();
    }
    const abortController = new AbortController();
    abortControllerRef = abortController;

    try {
        const response = await fetch(`${url}/emails?folderId=${folder.id}&mailboxType=${mailboxType}&skip=${skipValue}`, {
            signal: abortController.signal,
        });
        const data = await response.json();
        const newEmails = Array.isArray(data.emails) ? data.emails : [];

        setEmails((prevEmails) => {
            const emailIds = new Set(prevEmails.map(email => email.id));
            const filteredNewEmails = newEmails.filter(email => !emailIds.has(email.id));
            return [...prevEmails, ...filteredNewEmails];
        });

        setSkip(skipValue + newEmails.length);
        setHasMoreEmails(newEmails.length > 0);
    } catch (error) {
        if (error.name !== 'AbortError') {
            console.error('Error fetching emails for folder:', error);
        }
    } finally {
        setLoading(false);
        setIsLoadingMoreEmails(false);
        setIsFolderDisabled(false);
    }
};

export const searchEmails = async (
    url,
    folderId,
    mailboxType,
    query,
    searchField,
    setSearchResults
) => {
    try {
        const response = await fetch(`${url}/emails?folderId=${folderId}&mailboxType=${mailboxType}&searchQuery=${encodeURIComponent(query)}&searchField=${searchField}`);
        
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        setSearchResults(data.emails);
    } catch (error) {
        console.error('Error searching emails:', error);
    }
};
