import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faPen, faHome } from '@fortawesome/free-solid-svg-icons';
import './MessageBoxApp.css';
import PreviewComponent from './PreviewComponent';
import ManagementComponent from './ManagementComponent';
import ThemeSwitcher from '../../ThemeSwitcher';

const MessageBoxApp = () => {
  const [activeView, setActiveView] = useState('management');
  const navigate = useNavigate();

  return (
    <div className="m-mba-container">
      {/* 顶部导航栏 */}
      <div className="m-mba-navbar">
        <div className="m-mba-nav-left">
          <button
            className="m-mba-nav-button"
            onClick={() => navigate('/')}
          >
            <FontAwesomeIcon icon={faHome} />
          </button>
          <button
            className={`m-mba-nav-button ${activeView === 'preview' ? 'active' : ''}`}
            onClick={() => setActiveView('preview')}
          >
            <FontAwesomeIcon icon={faEye} />
          </button>
          <button
            className={`m-mba-nav-button ${activeView === 'management' ? 'active' : ''}`}
            onClick={() => setActiveView('management')}
          >
            <FontAwesomeIcon icon={faPen} />
          </button>
        </div>
        <div className="m-mba-nav-right">
          <ThemeSwitcher />
        </div>
      </div>

      <div className="m-mba-body">
        {activeView === 'preview' && <PreviewComponent />}
        {/*activeView === 'management' && <ManagementComponent />*/}
      </div>
    </div>
  );
};

export default MessageBoxApp;
