import React from 'react';
import EmailBasicInfo from './EmailBasicInfo';
import EmailBasicTag from './EmailBasicTag';
import EmailSupportTag from './EmailSupportTagSP';
import './EmailItem.css';

const EmailItem = ({ 
  subject, 
  from, 
  receivedDateTime, 
  toRecipients, 
  ccRecipients, 
  isRead, 
  categories, 
  hasAttachments, 
  importance, 
  bodyPreview, 
  selectedMailbox, 
  selectedFolder 
}) => {
  return (
    <div className="email-item">
      <EmailBasicInfo 
        subject={subject}
        from={from}
        receivedDateTime={receivedDateTime}
        toRecipients={toRecipients}
        ccRecipients={ccRecipients}
        bodyPreview={bodyPreview}
        selectedMailbox={selectedMailbox}
        selectedFolder={selectedFolder}
      />
      {selectedMailbox === 'Personal' && (
        <EmailBasicTag
          hasAttachments={hasAttachments}
          isRead={isRead}
          importance={importance}
          categories={categories}
        />
      )}
      {selectedMailbox === 'Support' && (
        <EmailSupportTag
          hasAttachments={hasAttachments}
          isRead={isRead}
          importance={importance}
          categories={categories}
          selectedFolder={selectedFolder}
          from={from}
          toRecipients={toRecipients}
          subject={subject}
          receivedDateTime={receivedDateTime}
        />
      )}
    </div>
  );
};

export default EmailItem;
