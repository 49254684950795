import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { setLanguage } from '../../../store';
import translations from './translations';
import './MessageBoxHomePage.css';
import MessageBoxEntry from './MessageBoxEntry';
import { motion } from 'framer-motion';

const languages = [
  { code: 'en', label: 'English', flag: '🇬🇧' },
  { code: 'zh', label: '中文', flag: '🇨🇳' },
  { code: 'es', label: 'Español', flag: '🇪🇸' },
  { code: 'fr', label: 'Français', flag: '🇫🇷' },
  { code: 'de', label: 'Deutsch', flag: '🇩🇪' },
  { code: 'pt', label: 'Português', flag: '🇵🇹' },
  { code: 'ru', label: 'Русский', flag: '🇷🇺' },
  { code: 'ar', label: 'العربية', flag: '🇸🇦' },
  { code: 'he', label: 'עברית', flag: '🇮🇱' },
  { code: 'ja', label: '日本語', flag: '🇯🇵' },
  { code: 'ko', label: '한국어', flag: '🇰🇷' },
  { code: 'it', label: 'Italiano', flag: '🇮🇹' },
];

const MessageBoxHomePage = ({ setTitle }) => {
  const dispatch = useDispatch();
  const currentLanguage = useSelector((state) => state.msgboxLanguage);
  const t = translations[currentLanguage];

  const [showEntry, setShowEntry] = useState(false);
  const [name, setName] = useState('');
  const [ipCity, setIpCity] = useState('');
  const [ipCountry, setIpCountry] = useState('');
  const [ipLanguage, setIpLanguage] = useState('');
  const [isLanguageSet, setIsLanguageSet] = useState(false);

  const handleLanguageChange = (selectedLanguage) => {
    dispatch(setLanguage(selectedLanguage));
    setIsLanguageSet(true); // 用户选择语言后，不再依赖 IP 语言
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const nameInput = e.target.name.value;
    setName(nameInput);
    setShowEntry(true);
  };

  useEffect(() => {
    setTitle(t['home-title']);

    const fetchLocation = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/msgbox/detect-location`);
        const { location, language } = response.data;
        const [city, country] = location.split(',').map((item) => item.trim());
        setIpCity(city);
        setIpCountry(country);
        setIpLanguage(language);

        // 仅在未设置语言的情况下使用 IP 语言来设置语言
        if (language && !isLanguageSet) {
          const baseLanguage = language.split('-')[0]; // 获取语言代码的主要部分
          const selectedLanguage = languages.find((lang) => lang.code === baseLanguage) ? baseLanguage : 'en';
          handleLanguageChange(selectedLanguage);
        }
      } catch (error) {
        console.error('Error fetching location:', error);
        if (!isLanguageSet) {
          handleLanguageChange('en');
        }
      }
    };

    // 仅在 IP 城市或国家未设置时调用 fetchLocation
    if (!ipCity || !ipCountry) {
      fetchLocation();
    }

    const intervalId = setInterval(() => {
      if (!isLanguageSet) { // 仅在未设置语言的情况下周期性地调用
        fetchLocation();
      }
    }, 10 * 60 * 1000);

    return () => clearInterval(intervalId);
  }, [currentLanguage, setTitle, t, ipCity, ipCountry, isLanguageSet]);

  const isRTL = ['ar', 'he'].includes(currentLanguage);

  return (
    <div className={`mbh-container ${isRTL ? 'rtl' : ''}`}>
      {!showEntry ? (
        <motion.form
          className="mbh-form"
          onSubmit={handleSubmit}
          initial={{ opacity: 1 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5 }}
        >
          <motion.div className="mbh-top-group" layout>
            <div className="mbh-form-group">
              <label htmlFor="name">{t['home-nameLabel']}</label>
              <input type="text" id="name" placeholder={t['home-nameLabel']} required />
            </div>
            <div className="mbh-form-group">
              <label htmlFor="email">{t['home-emailLabel']}</label>
              <input type="email" id="email" placeholder={t['home-emailLabel']} required />
            </div>
            <div className="mbh-form-group">
              <label>{t['home-languageLabel'] || 'Language'}</label>
              <div className="mbh-language-buttons">
                {languages.map((lang, index) => (
                  <motion.button
                    key={lang.code}
                    type="button"
                    className={`language-button ${currentLanguage === lang.code ? 'active' : ''}`}
                    onClick={() => handleLanguageChange(lang.code)}
                    title={lang.label}
                    initial={{ opacity: 0, y: 0 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.3 + index * 0.06, duration: 0.5 }}
                  >
                    {lang.flag}
                  </motion.button>
                ))}
              </div>
            </div>
          </motion.div>

          <div className="mbh-bottom-group">
            <button type="submit" className="mbh-continue-btn">
              {t['home-continue']}
            </button>
          </div>
        </motion.form>
      ) : (
        <MessageBoxEntry 
          name={name} 
          ipCity={ipCity} 
          ipCountry={ipCountry} 
          ipLanguage={ipLanguage}
          selectedLanguage={currentLanguage}
        />
      )}
    </div>
  );
};

export default MessageBoxHomePage;
