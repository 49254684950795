import React, { useState, useRef, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import MailboxAndFolders from './MailboxAndFolders';
import EmailsList from './EmailsList';
import EmailDetail from './EmailDetail';
import SearchEmails from './SearchEmails';
import { filterEmails } from './emailSearchFilter';
import EmailControlBar from './EmailControlBar';
import ComposeEmail from './ComposeEmail';
import { useNavigate } from 'react-router-dom';
import ProgressBar from './ProgressBar';
import EmailTaggerMS from './email-tagger/EmailTaggerMS';
import EmailControlManualTag from './EmailControlManualTag';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolder } from '@fortawesome/free-solid-svg-icons';
import './EmailApp.css';
import ThemeSwitcher from '../../ThemeSwitcher';
import EmailNotifications from './EmailNotifications';
import { initializeSocket, disconnectSocket, fetchEmailsFromSelectedFolder, searchEmails } from './emailService';

const EmailApp = () => {
  // 1. State 管理部分
  const [emails, setEmails] = useState([]);
  const navigate = useNavigate();
  const [newEmails, setNewEmails] = useState([]);
  const [selectedEmail, setSelectedEmail] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedMailbox, setSelectedMailbox] = useState(null);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [progress, setProgress] = useState(0);
  const [skip, setSkip] = useState(0);
  const [isLoadingMoreEmails, setIsLoadingMoreEmails] = useState(false);
  const [hasMoreEmails, setHasMoreEmails] = useState(true);
  const [isFolderDisabled, setIsFolderDisabled] = useState(false);
  const [isMailboxVisible, setIsMailboxVisible] = useState(true);
  const [isEmailsListWideEnough, setIsEmailsListWideEnough] = useState(true);
  const [composeEmailVisible, setComposeEmailVisible] = useState(false);
  const [isReplying, setIsReplying] = useState(false);
  const [sendStatus, setSendStatus] = useState('idle');
  const [isAttachmentModalOpen, setIsAttachmentModalOpen] = useState(false);
  const [attachmentCount, setAttachmentCount] = useState(0);
  const [searchResults, setSearchResults] = useState([]);
  const [showManualTag, setShowManualTag] = useState(false);

  const composeEmailRef = useRef(null);
  const emailsRef = useRef(emails);

  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 950);
  const hideMailboxTimeoutRef = useRef(null);

  const handleEmailsListWidthChange = (isWideEnough) => {
    if (!isWideEnough && isMailboxVisible) {
      // 当宽度不足并且 Mailbox 仍然可见时，启动定时器隐藏 Mailbox
      clearTimeout(hideMailboxTimeoutRef.current);  // 确保只有一个定时器在运行
      hideMailboxTimeoutRef.current = setTimeout(() => {
        setIsMailboxVisible(false);
      }, 3000);
    }
  };

  useEffect(() => {
    if (isMailboxVisible) {
      clearTimeout(hideMailboxTimeoutRef.current);  // 清除定时器
    }
  }, [isMailboxVisible]);

  useEffect(() => {
    return () => {
      // 清除组件卸载时的定时器
      clearTimeout(hideMailboxTimeoutRef.current);
    };
  }, []);

  
  useEffect(() => {
    const handleResize = () => {
      const wasMobileView = isMobileView;
      const isNowMobileView = window.innerWidth < 950;
  
      setIsMobileView(isNowMobileView);
  
      // 如果从大屏幕变为小屏幕，清空选中的邮件
      if (!wasMobileView && isNowMobileView) {
        setSelectedEmail(null);
      }
    };
  
    window.addEventListener('resize', handleResize);
  
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isMobileView]);


  const selectEmail = (email) => {
    setSelectedEmail(email);
  };

  const onMobileClickBack = () => {
    if (isMobileView && selectedEmail) {
      setSelectedEmail(null);  // 在移动视图且有选中的邮件时，返回邮件列表
    } else {
      navigate('/');  // 否则，导航到其他页面或执行其他默认行为
    }
  };
  
  
  // 2. 初始化和清理副作用，例如 socket 连接
  useEffect(() => {
    initializeSocket(
      `${process.env.REACT_APP_API_BASE_URL}/outlook`,
      setEmails,
      setProgress,
      handleEmailUpdate
    );

    return () => {
      disconnectSocket();
    };
  }, []);

  useEffect(() => {
    emailsRef.current = emails;
  }, [emails]);

  // 控制进度条重置
  useEffect(() => {
    if (progress === 100) {
      setTimeout(() => {
        setProgress(0);
      }, 500);
    }
  }, [progress]);

  // 根据选中的文件夹和邮箱加载邮件
  useEffect(() => {
    if (selectedFolder && selectedMailbox) {
      fetchEmailsFromSelectedFolder(
        `${process.env.REACT_APP_API_BASE_URL}/outlook`,
        selectedFolder,
        selectedMailbox,
        skip,
        setLoading,
        setEmails,
        setSkip,
        setHasMoreEmails,
        setIsLoadingMoreEmails,
        setIsFolderDisabled
      );
    } else {
      resetState();
    }
  }, [selectedFolder, selectedMailbox]);

  // 3. 核心逻辑函数
  const resetState = () => {
    setEmails([]);
    setSelectedEmail(null);
    setLoading(false);
    setProgress(0);
    setSkip(0);
    setIsLoadingMoreEmails(false);
    setHasMoreEmails(true);
  };

  const searchResultsRef = useRef(searchResults);

  // 每次更新 searchResults 时，更新 ref
  useEffect(() => {
    searchResultsRef.current = searchResults;
  }, [searchResults]);
  
  const handleEmailUpdate = (updatedEmail) => {
    console.log('Updated email received:', updatedEmail); // 调试信息
  
    setEmails((prevEmails) => {
      // 首先检查当前是否有搜索结果
      if (searchResultsRef.current.length > 0) { // 使用 searchResultsRef
        console.log('Search results before update:', searchResultsRef.current); // 调试信息
  
        // 更新搜索结果
        const updatedSearchResults = [...searchResultsRef.current];
        const existingIndex = updatedSearchResults.findIndex(email => email.id === updatedEmail.id);
  
        if (existingIndex !== -1) {
          // 如果在搜索结果中找到相同的邮件，更新它
          updatedSearchResults[existingIndex] = { ...updatedSearchResults[existingIndex], ...updatedEmail };
          console.log('Updated existing email in search results:', updatedSearchResults[existingIndex]); // 调试信息
        }
  
        // 更新搜索结果状态
        setSearchResults(updatedSearchResults);
        console.log('Search results after update:', updatedSearchResults); // 调试信息
      }
  
      // 然后更新完整的邮件列表
      const existingEmailIndex = prevEmails.findIndex(email => email.id === updatedEmail.id);
      if (existingEmailIndex !== -1) {
        // 只更新已存在的邮件，不添加新的邮件
        const updatedEmails = [...prevEmails];
        updatedEmails[existingEmailIndex] = { ...updatedEmails[existingEmailIndex], ...updatedEmail };
        return updatedEmails;
      }
      // 如果没有找到相同的邮件，返回原来的邮件列表，不做任何更新
      return prevEmails;
    });
  };
  
  const handleSearch = (query, searchField, onSearchComplete) => {
    if (selectedMailbox && selectedFolder) {
      // 如果 query 是 "Processing by"，则将查询字符串改为 "Follow-Up"
      const searchQuery = query === 'Processing by' ? 'Follow-Up' : query;
  
      searchEmails(
        `${process.env.REACT_APP_API_BASE_URL}/outlook`,
        selectedFolder.id,
        selectedMailbox,
        searchQuery, // 使用修改后的查询字符串
        searchField,
        (results) => {
          // 使用原始的 query 进行标签过滤
          let currentTag = searchField === 'categories' && (query === 'New' || query === 'Processing by') ? query : searchField;
          
          // 对返回的结果进行过滤
          const filteredResults = filterEmails(results, currentTag);
          
          // 按时间排序结果
          const sortedResults = filteredResults.sort((a, b) => new Date(b.receivedDateTime) - new Date(a.receivedDateTime));
          
          // 更新搜索结果
          setSearchResults(sortedResults);
          
          // 调用回调函数，指示是否有结果
          onSearchComplete(sortedResults.length === 0, sortedResults.length > 0);
        }
      );
    }
  };
  

  const onSearchCancel = () => {
    setSearchResults([]);
  };

  const handleLoadMore = () => {
    if (selectedFolder && selectedMailbox && !isLoadingMoreEmails && hasMoreEmails) {
      fetchEmailsFromSelectedFolder(
        `${process.env.REACT_APP_API_BASE_URL}/outlook`,
        selectedFolder,
        selectedMailbox,
        skip,
        setLoading,
        setEmails,
        setSkip,
        setHasMoreEmails,
        setIsLoadingMoreEmails,
        setIsFolderDisabled
      );
    }
  };

  const handleFolderSelection = (folder, mailboxType) => {
    disconnectSocket();
    resetState();
    setIsFolderDisabled(true);

    initializeSocket(
      `${process.env.REACT_APP_API_BASE_URL}/outlook`,
      setEmails,
      setProgress,
      handleEmailUpdate,
      mailboxType,
    );

    setSelectedMailbox(mailboxType);
    setSelectedFolder(folder);
    setSearchResults([]);
  };

  const handleNewEmail = () => {
    setIsReplying(false);
    setComposeEmailVisible(true);
  };

  const handleReplyEmail = () => {
    if (selectedEmail) {
      setIsReplying(true);
      setComposeEmailVisible(true);
    }
  };

  const handleCloseComposeEmail = () => {
    setComposeEmailVisible(false);
    setIsAttachmentModalOpen(false);
  };

  const handleSendEmail = () => {
    if (composeEmailRef.current) {
      composeEmailRef.current.handleSend();
    }
  };

  const onSendStatusChange = (status) => {
    setSendStatus(status);
    if (status === 'success' || status === 'error') {
      setTimeout(() => {
        setSendStatus('idle');
      }, 2000);
    }
  };

  const handleAttachmentClick = () => {
    setIsAttachmentModalOpen(prev => !prev);
  };

  const toggleManualTag = () => {
    setShowManualTag(!showManualTag);
  };

  const handleRefreshEmails = () => {
    // 清空当前的 emails 列表
    setEmails([]);
  
    // 重新调用 fetchEmailsFromSelectedFolder 以获取最新的邮件
    fetchEmailsFromSelectedFolder(
      `${process.env.REACT_APP_API_BASE_URL}/outlook`,
      selectedFolder,
      selectedMailbox,
      0, // 重置分页起始点
      setLoading,
      setEmails,
      setSkip,
      setHasMoreEmails,
      setIsLoadingMoreEmails,
      setIsFolderDisabled
    );
  
    // 清空 newEmails 列表
    setNewEmails([]);
  };

  // 5. 渲染部分
  return (
    <div className="eax-email-app">
      {/* 顶部导航栏 */}
      <div className="eax-nav-bar">
        <div className="eax-nav-left">
          <button 
            className="eax-toggle-mailbox-button" 
            onClick={() => setIsMailboxVisible(prev => !prev)}
          >
            <FontAwesomeIcon icon={faFolder} className="eax-toggle-mailbox-icon" />
          </button>
        </div>
        <div className="eax-nav-center">
          <SearchEmails onSearch={handleSearch} onSearchCancel={onSearchCancel} selectedMailbox={selectedMailbox}/>
        </div>
        <div className="eax-nav-right">
          {/* <EmailNotifications newEmails={newEmails} onRefreshEmails={handleRefreshEmails} /> */}
          {progress > 0 && <ProgressBar progress={progress} />}
          <ThemeSwitcher />
        </div>
      </div>

      {/* 主体内容 */}
      <main className="eax-app-main">
        {/* 左侧邮箱和文件夹栏 */}
        <motion.div
          initial={{ x: 0 }}
          animate={{ x: isMailboxVisible ? 0 : -250 }}
          transition={{ type: 'spring', stiffness: 250, damping: 30 }}
          className="eax-mailbox-container"
        >
          <MailboxAndFolders 
            onSelectFolder={handleFolderSelection} 
            isFolderDisabled={isFolderDisabled} 
          />
        </motion.div>
        
        {/* 邮件列表和详情 */}
        <motion.div
          initial={{ marginLeft: 0 }}
          animate={{ marginLeft: isMailboxVisible ? 0 : -250 }}
          transition={{ type: 'spring', stiffness: 250, damping: 30 }}
          className="eax-emails-and-detail-container"
        >
        {!isMobileView && (
          <>
            <EmailsList 
              className="active"
              emails={searchResults.length > 0 ? searchResults : emails} 
              onSelectEmail={selectEmail} 
              onLoadMore={handleLoadMore} 
              selectedMailbox={selectedMailbox} 
              selectedFolder={selectedFolder} 
              onWidthChange={handleEmailsListWidthChange} 
            />
            <EmailDetail 
              className={'active'}  // 始终显示 EmailDetail 组件
              email={selectedEmail} 
              selectedMailbox={selectedMailbox}
            />
          </>
        )}

        {/* 小屏幕显示: 仅在未选中邮件时显示 EmailsList，选中后显示 EmailDetail */}
        {isMobileView && (
          <>
            {!selectedEmail ? (
              <EmailsList 
                className="active"
                emails={searchResults.length > 0 ? searchResults : emails} 
                onSelectEmail={selectEmail} 
                onLoadMore={handleLoadMore} 
                selectedMailbox={selectedMailbox} 
                selectedFolder={selectedFolder} 
                onWidthChange={handleEmailsListWidthChange} 
              />
            ) : (
              <EmailDetail 
                className="active"
                email={selectedEmail} 
                selectedMailbox={selectedMailbox}
              />
            )}
          </>
        )}
      </motion.div>

        <EmailTaggerMS
          emails={searchResults.length > 0 ? searchResults : emails}
          selectedMailbox={selectedMailbox}
          selectedFolder={selectedFolder}
        />
      </main>

      {/* 底部控制栏 */}
      <EmailControlBar 
        composeEmailVisible={composeEmailVisible}
        onNewEmail={handleNewEmail} 
        onReplyEmail={handleReplyEmail}
        onCloseComposeEmail={handleCloseComposeEmail} 
        onSendEmail={handleSendEmail}
        sendStatus={sendStatus}
        onAttachmentClick={handleAttachmentClick}
        attachmentCount={attachmentCount}  
        selectedFolder={selectedFolder}
        selectedMailbox={selectedMailbox}
        selectedEmail={selectedEmail}
        onToggleManualTag={toggleManualTag}
        onMobileClickBack={onMobileClickBack} 
      />

      {/* 撰写邮件/回复邮件的弹窗 */}
      <AnimatePresence>
        {composeEmailVisible && (
          <ComposeEmail 
            ref={composeEmailRef}
            onClose={handleCloseComposeEmail} 
            selectedMailbox={selectedMailbox} 
            isReplying={isReplying} 
            email={isReplying ? selectedEmail : null}
            onSendStatusChange={onSendStatusChange}
            isAttachmentModalOpen={isAttachmentModalOpen}
            setIsAttachmentModalOpen={setIsAttachmentModalOpen} 
            setAttachmentCount={setAttachmentCount}
          />
        )}
      </AnimatePresence>

      {/* 手动标签弹窗 */}
      <AnimatePresence>
        {showManualTag && (
          <EmailControlManualTag
            selectedMailbox={selectedMailbox}
            selectedFolder={selectedFolder}
            selectedEmail={selectedEmail}
          />
        )}
      </AnimatePresence>
    </div>
  );
};

export default EmailApp;
