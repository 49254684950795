// src/components/EmailEditor.js
import React, { useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './EmailEditor.css'; // 单独的样式文件

const EmailEditor = ({ body, setBody, theme }) => {
  const modules = {
    toolbar: [
      [{size: []}],
      ['bold', 'italic', 'underline', 'blockquote'],
      [{'list': 'ordered'}, {'list': 'bullet'}, 
       {'indent': '-1'}, {'indent': '+1'}],
      ['link'],
      ['clean']
    ],
  };

  const formats = [
    'header', 'font', 'size',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image', 'video'
  ];

  useEffect(() => {
    const editorContainer = document.querySelector('.ql-editor');
    const toolbarContainer = document.querySelector('.ql-toolbar');
    const quillContainer = document.querySelector('.ql-container');
    if (editorContainer) {
      editorContainer.style.color = 'var(--text-color-1)';
      toolbarContainer.style.backgroundColor = 'var(--bg-color-1)';
      toolbarContainer.style.color = 'var(--text-color-1)';
      toolbarContainer.style.borderColor = 'var(--line-color)';
      quillContainer.style.borderColor = 'var(--line-color)';
    }
  }, [theme]);

  return (
    <ReactQuill
      value={body}
      onChange={setBody}
      modules={modules}
      formats={formats}
      style={{ height: '60vh', marginBottom: '90px' }} 
    />
  );
};

export default EmailEditor;
