import React, { useEffect, useState, useRef } from 'react';
import './AttachmentPreview.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faEnvelope, faTimes, faChevronLeft, faChevronRight, faFileArchive, faFilePdf, faFileImage, faFileAlt, faFileWord, faFileExcel, faFileVideo, faFileAudio, faFile } from '@fortawesome/free-solid-svg-icons';

const getIconByFileType = (contentType, fileName) => {
  if (!fileName || !fileName.includes('.')) return faEnvelope; // Default icon for files without a suffix

  if (contentType && contentType.includes('pdf')) return faFilePdf;
  if (contentType && (contentType.includes('image') || contentType.includes('png') || contentType.includes('jpg') || contentType.includes('jpeg') || contentType.includes('gif'))) return faFileImage;
  if (contentType && contentType.includes('text')) return faFileAlt;
  if (contentType && contentType.includes('word')) return faFileWord;
  if (contentType && contentType.includes('excel')) return faFileExcel;
  if (contentType && (contentType.includes('zip') || contentType.includes('rar'))) return faFileArchive;
  if (contentType && contentType.includes('video')) return faFileVideo;
  if (contentType && contentType.includes('audio')) return faFileAudio;
  return faFile;
};

const AttachmentPreview = ({ attachments, initialIndex, onClose }) => {
  const [currentAttachmentIndex, setCurrentAttachmentIndex] = useState(initialIndex);
  const thumbnailsRef = useRef(null);
  const currentAttachment = attachments[currentAttachmentIndex];
  const canPreview = currentAttachment && currentAttachment.contentType && (currentAttachment.contentType.includes('image') || currentAttachment.contentType.includes('pdf') || currentAttachment.contentType.includes('video'));
  const isEmlFile = currentAttachment && (!currentAttachment.name.includes('.'));

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.keyCode === 27) {
        handleClose();
      } else if (event.keyCode === 37) {
        goToPrevious();
      } else if (event.keyCode === 39) {
        goToNext();
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    if (thumbnailsRef.current) {
      const thumbnailsContainer = thumbnailsRef.current;
      const thumbnail = thumbnailsContainer.children[currentAttachmentIndex];
      if (thumbnail) {
        const containerWidth = thumbnailsContainer.clientWidth;
        const thumbnailWidth = thumbnail.clientWidth;
        const scrollPosition = thumbnail.offsetLeft - (containerWidth / 2) + (thumbnailWidth / 2);
        thumbnailsContainer.scrollTo({ left: scrollPosition, behavior: 'smooth' });

        if (thumbnailsContainer.scrollWidth > thumbnailsContainer.clientWidth) {
          thumbnailsContainer.classList.add('has-scroll');
        } else {
          thumbnailsContainer.classList.remove('has-scroll');
        }
      }
    }
  }, [currentAttachmentIndex]);

  const handleClose = () => {
    const overlay = document.querySelector('.ap2-attachment-preview-overlay');
    const container = document.querySelector('.ap2-attachment-preview-container');
    if (overlay && container) {
      container.style.animation = 'ap2-scaleOut 0.3s forwards';
      overlay.style.animation = 'ap2-fadeOut 0.3s forwards';
      setTimeout(() => {
        onClose();
      }, 300);
    }
  };

  const handleThumbnailClick = (index) => {
    setCurrentAttachmentIndex(index);
  };

  const goToPrevious = () => {
    setCurrentAttachmentIndex((prevIndex) => (prevIndex === 0 ? attachments.length - 1 : prevIndex - 1));
  };

  const goToNext = () => {
    setCurrentAttachmentIndex((prevIndex) => (prevIndex === attachments.length - 1 ? 0 : prevIndex + 1));
  };

  const downloadCurrentAttachment = () => {
    if (currentAttachment) {
      const link = document.createElement('a');
      link.href = `data:${currentAttachment.contentType};base64,${currentAttachment.contentBytes}`;
      link.download = currentAttachment.name;
      link.click();
    }
  };

  const downloadAllAttachments = () => {
    attachments.forEach((attachment) => {
      const link = document.createElement('a');
      link.href = `data:${attachment.contentType};base64,${attachment.contentBytes}`;
      link.download = attachment.name;
      link.click();
    });
  };

  return (
    <div className="ap2-attachment-preview-overlay">
      <div className="ap2-attachment-preview-container">
        <div className="ap2-attachment-preview-header">
          <div className="ap2-attachment-preview-title">{currentAttachment && currentAttachment.name}</div>
          <button className="ap2-attachment-preview-download-all" onClick={downloadAllAttachments}>
            <FontAwesomeIcon icon={faDownload} /> All
          </button>
          <button className="ap2-attachment-preview-close" onClick={handleClose}>
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>
        <div className="ap2-attachment-preview-content">
          {isEmlFile ? (
            <div className="ap2-attachment-preview-unavailable">
              This could be an email attachment. If you can't download please use Outlook to check.
            </div>
          ) : canPreview ? (
            currentAttachment.contentType.includes('image') ? (
              <img src={`data:${currentAttachment.contentType};base64,${currentAttachment.contentBytes}`} alt={currentAttachment.name} className="ap2-attachment-preview-image" />
            ) : currentAttachment.contentType.includes('pdf') ? (
              <iframe
                src={`data:${currentAttachment.contentType};base64,${currentAttachment.contentBytes}`}
                title={currentAttachment.name}
                className="ap2-attachment-preview-iframe"
              ></iframe>
            ) : currentAttachment.contentType.includes('video') ? (
              <video controls className="ap2-attachment-preview-video">
                <source src={`data:${currentAttachment.contentType};base64,${currentAttachment.contentBytes}`} type={currentAttachment.contentType} />
                Your browser does not support the video tag.
              </video>
            ) : (
              <div className="ap2-attachment-preview-unavailable">No Preview</div>
            )
          ) : (
            <div className="ap2-attachment-preview-unavailable">No Preview</div>
          )}
        </div>
        <div className="ap2-attachment-preview-footer">
          <button className="ap2-attachment-preview-download" onClick={downloadCurrentAttachment}>
            <FontAwesomeIcon icon={faDownload} /> Download
          </button>
          <div className="ap2-attachment-preview-navigation">
            <button className="ap2-attachment-preview-arrow left" onClick={goToPrevious}>
              <FontAwesomeIcon icon={faChevronLeft} />
            </button>
            <div className="ap2-attachment-preview-thumbnails-container">
              <div className="ap2-attachment-preview-thumbnails" ref={thumbnailsRef}>
                {attachments.map((attachment, index) => (
                  <div key={attachment.id} className="ap2-attachment-preview-thumbnail-container">
                    {attachment.contentType && attachment.contentType.includes('image') ? (
                      <img
                        src={`data:${attachment.contentType};base64,${attachment.contentBytes}`}
                        alt={attachment.name}
                        className={`ap2-attachment-preview-thumbnail ${index === currentAttachmentIndex ? 'active' : ''}`}
                        onClick={() => handleThumbnailClick(index)}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={getIconByFileType(attachment.contentType, attachment.name)}
                        className={`ap2-attachment-preview-thumbnail-icon ${index === currentAttachmentIndex ? 'active' : ''}`}
                        onClick={() => handleThumbnailClick(index)}
                        style={{ fontSize: '60px', cursor: 'pointer' }}
                      />
                    )}
                    <div className="ap2-attachment-preview-thumbnail-name">{attachment.name}</div>
                  </div>
                ))}
              </div>
            </div>
            <button className="ap2-attachment-preview-arrow right" onClick={goToNext}>
              <FontAwesomeIcon icon={faChevronRight} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AttachmentPreview;
