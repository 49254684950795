import React, { useState, useEffect, useCallback, useRef } from 'react';
import './MailboxAndFolders.css';

const MailboxAndFolders = ({ onSelectFolder, isFolderDisabled }) => {
  const [personalFolders, setPersonalFolders] = useState([]);
  const [supportFolders, setSupportFolders] = useState([]);
  const [selectedFolder, setSelectedFolder] = useState(null);

  const fetchedFolders = useRef({ personal: false, support: false });
  const fetchCalled = useRef(false);

  const fetchFolders = useCallback(async (mailboxType) => {
    if (fetchedFolders.current[mailboxType]) {
      return;
    }

    let endpoint = '';
    switch (mailboxType) {
      case 'personal':
        endpoint = 'personalMailFolders';
        break;
      case 'support':
        endpoint = 'supportMailFolders';
        break;
      default:
        console.error(`Unknown mailbox type: ${mailboxType}`);
        return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/outlook/${endpoint}`);
      const data = await response.json();
      if (mailboxType === 'personal') {
        setPersonalFolders(Array.isArray(data) ? data : []);
      } else if (mailboxType === 'support') {
        setSupportFolders(Array.isArray(data) ? data : []);
        if (!selectedFolder && Array.isArray(data) && data.length > 0) {
          const inboxFolder = data.find(folder => folder.displayName.toLowerCase() === 'inbox') || data[0];
          setSelectedFolder(inboxFolder);
          onSelectFolder(inboxFolder, 'Support');
        }
      }
      fetchedFolders.current[mailboxType] = true;
    } catch (error) {
      console.error(`Error fetching ${mailboxType} mail folders:`, error);
    }
  }, [onSelectFolder, selectedFolder]);

  useEffect(() => {
    if (!fetchCalled.current) {
      fetchFolders('support');
      fetchFolders('personal');
      fetchCalled.current = true;
    }
  }, [fetchFolders]);

  const handleFolderClick = (folder, mailboxType) => {
    if (!isFolderDisabled) {
      setSelectedFolder(folder);
      switch (mailboxType) {
        case 'personal':
          onSelectFolder(folder, 'Personal');
          break;
        case 'support':
          onSelectFolder(folder, 'Support');
          break;
        default:
          console.error('Unknown mailbox type:', mailboxType);
      }
    }
  };

  return (
    <div className="mailbox-and-folders">
      <div className="mailbox">
        <h3>Support Mailbox</h3>
        <ul className="folder-list">
          {Array.isArray(supportFolders) ? supportFolders.map((folder) => (
            <li
              key={folder.id}
              onClick={() => handleFolderClick(folder, 'support')}
              className={`${selectedFolder?.id === folder.id ? 'selected' : ''} ${isFolderDisabled ? 'disabled' : ''}`}
            >
              {folder.displayName}
            </li>
          )) : <li>Error loading folders</li>}
        </ul>
      </div>
     {/* <div className="mailbox">
        <h3>Personal Mailbox</h3>
        <ul className="folder-list">
          {Array.isArray(personalFolders) ? personalFolders.map((folder) => (
            <li
              key={folder.id}
              onClick={() => handleFolderClick(folder, 'personal')}
              className={`${selectedFolder?.id === folder.id ? 'selected' : ''} ${isFolderDisabled ? 'disabled' : ''}`}
            >
              {folder.displayName}
            </li>
          )) : <li>Error loading folders</li>}
        </ul>
      </div> */}
    </div>
  );
};

export default MailboxAndFolders;
