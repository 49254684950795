import React from 'react';
import { useSelector } from 'react-redux';
import './EmailBasicInfo.css';

const formatReceivedDateTime = (receivedDateTime) => {
  const now = new Date();
  const date = new Date(receivedDateTime);

  const isToday = now.toDateString() === date.toDateString();
  const isYesterday = now.getDate() - date.getDate() === 1 &&
                      now.getMonth() === date.getMonth() &&
                      now.getFullYear() === date.getFullYear();

  const timeString = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

  if (isToday) {
    return timeString; // 今天只显示时间
  } else if (isYesterday) {
    return `Yesterday, ${timeString}`; // 昨天显示“Yesterday”加上时间
  } else {
    // 显示日期和时间
    return `${date.toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: '2-digit' })}, ${timeString}`;
  }
};


const formatRecipients = (recipients, maxVisible) => {
  if (recipients.length <= maxVisible) {
    return recipients.map(recipient => recipient.emailAddress.name || recipient.emailAddress.address).join(', ');
  } else {
    const visibleRecipients = recipients.slice(0, maxVisible);
    return visibleRecipients.map(recipient => recipient.emailAddress.name || recipient.emailAddress.address).join(', ') + '...';
  }
};

// Function to filter out repeated underscores
const filterUnderscores = (text) => {
  return text.replace(/_{2,}/g, ''); // Remove two or more underscores
};

const EmailBasicInfo = ({ subject, from, receivedDateTime, toRecipients, ccRecipients, bodyPreview, selectedMailbox, selectedFolder }) => {
  const maxVisibleRecipients = 2; // 可显示的最大接收者数量

  const formattedTo = formatRecipients(toRecipients, maxVisibleRecipients);
  const formattedCc = ccRecipients && ccRecipients.length > 0 ? formatRecipients(ccRecipients, maxVisibleRecipients) : null;

  // Filter underscores from bodyPreview
  const filteredBodyPreview = filterUnderscores(bodyPreview);

  const userEmail = useSelector(state => state.user.email); // 从 store 获取用户邮箱地址


  let displayTag = null;

  // 判断标签显示逻辑
  if (selectedMailbox === 'Personal' && (selectedFolder?.displayName === 'Inbox') && from.emailAddress.address === userEmail) {
    displayTag = 'Sent';
  } else if (selectedMailbox === 'Personal' && (selectedFolder?.displayName === 'Sent Items') && toRecipients.some(recipient => recipient.emailAddress.address === userEmail)) {
    displayTag = 'Inbox';
  } else if (selectedMailbox === 'Personal' && ccRecipients && ccRecipients.some(recipient => recipient.emailAddress.address === userEmail)) {
    displayTag = 'CC';
  } else if (selectedMailbox === 'Support') {
    const supportEmail = 'support-pool@fraba.com';
    if (selectedFolder?.displayName === 'Inbox' && from.emailAddress.address === supportEmail) {
      displayTag = 'Sent';
    } else if (selectedFolder?.displayName === 'Sent Items' && toRecipients.some(recipient => recipient.emailAddress.address === supportEmail)) {
      displayTag = 'Inbox';
    } else if (ccRecipients && ccRecipients.some(recipient => recipient.emailAddress.address === supportEmail)) {
      displayTag = 'CC';
    }
  }


  return (
    <div className="ebi-email-basic-info">
      <div className="ebi-email-header">
        <div className="ebi-email-subject-time">
          {displayTag && <span className="ebi-email-tag">{displayTag}</span>}
          <div className="ebi-email-subject">{subject}</div>
          <time className="ebi-email-time">{formatReceivedDateTime(receivedDateTime)}</time>
        </div>
      </div>
      <div className={`ebi-email-recipients ${formattedCc ? '' : 'ebi-no-cc'}`}>
        <div className="ebi-email-from">
          <strong>From:</strong> {from.emailAddress.name || from.emailAddress.address}
        </div>
        <div className="ebi-email-to">
          <strong>To:</strong> {formattedTo}
        </div>
        {formattedCc && (
          <div className="ebi-email-cc">
            <strong>CC:</strong> {formattedCc}
          </div>
        )}
      </div>
      <div className="ebi-email-preview">
        <div>{filteredBodyPreview}</div>
      </div>
    </div>
  );
};

export default EmailBasicInfo;
