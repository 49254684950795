import React, { useEffect, useState } from 'react';
import { motion, useAnimation } from 'framer-motion';
import './ProgressBar.css';

const ProgressBar = ({ progress }) => {
  const [displayedProgress, setDisplayedProgress] = useState(0);
  const circleRadius = 10;
  const circleCircumference = 2 * Math.PI * circleRadius;
  const progressOffset = circleCircumference - (displayedProgress / 100) * circleCircumference;

  const controls = useAnimation();

  useEffect(() => {
    const incrementProgress = () => {
      if (displayedProgress < progress) {
        setDisplayedProgress((prev) => Math.min(prev + 1, progress));
      }
    };

    const interval = setInterval(incrementProgress, 10); // 每10ms更新一次进度

    return () => clearInterval(interval);
  }, [progress, displayedProgress]);

  useEffect(() => {
    if (progress === 100) {
      controls.start({
        opacity: 0,
        scale: 1.1,
        transition: { duration: 0.3, ease: "easeInOut" },
      }).then(() => {
        controls.start({ scale: 1 });
      });
    } else if (progress > 0) {
      controls.start({ opacity: 1, scale: 1 });
    }
  }, [progress, controls]);

  return (
    <motion.div
      className="progress-circle-container"
      initial={{ opacity: 0, scale: 1 }}
      animate={controls}
    >
      <motion.svg
        className="progress-circle"
        width="40"
        height="40"
        animate={displayedProgress > 0 && displayedProgress < 100 ? { rotate: [0, 360] } : { rotate: 0 }}
        transition={{
          rotate: { duration: 2, ease: "linear", repeat: Infinity },
        }}
      >
        <circle
          className="progress-circle-bg"
          cx="20"
          cy="20"
          r={circleRadius}
          fill="none"
          stroke="transparent"
          strokeWidth="5"
        />
        <motion.circle
          className="progress-circle-fill"
          cx="20"
          cy="20"
          r={circleRadius}
          fill="none"
          stroke="var(--highlight-color)" /* Apple Blue */
          strokeWidth="3"
          strokeDasharray={circleCircumference}
          strokeDashoffset={progressOffset}
          transition={{ 
            strokeDashoffset: { duration: 0.8, ease: [0.42, 0, 0.58, 1] }, // 更平滑的缓动
          }}
        />
      </motion.svg>
    </motion.div>
  );
};

export default ProgressBar;
