import { useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import { useDispatch } from 'react-redux';
import { setUser, setAvatar, setMailboxes } from '../store'; // Adjust import paths as necessary
import { Client } from '@microsoft/microsoft-graph-client';

const UserFetcher = () => {
  const { instance, accounts } = useMsal();
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchUserDetails = async () => {
      if (accounts.length === 0) return;

      const account = accounts[0];
      const tokenResponse = await instance.acquireTokenSilent({
        account: account,
        scopes: ["User.Read", "Mail.Read", "Mail.ReadWrite"] // Add necessary scopes
      });

      if (!tokenResponse) return;
      const client = Client.init({
        authProvider: (done) => {
          done(null, tokenResponse.accessToken);
        }
      });

      const userDetails = await client.api('/me').get();

      const photoResponse = await client.api('/me/photo/$value').responseType('blob').get();
      const reader = new FileReader();

      reader.onload = () => {
        dispatch(setAvatar(reader.result));
        dispatch(setUser({ id: account.localAccountId, displayName: userDetails.displayName, email: userDetails.mail })); // 增加email字段

      };

      reader.readAsDataURL(photoResponse);

      // Fetch user's mailbox addresses
      const mailFolders = await client.api('/me/mailFolders').get();
      const mailboxAddresses = mailFolders.value.map(folder => folder.id);
      dispatch(setMailboxes(mailboxAddresses)); // Dispatch the mailbox addresses to Redux store
    };

    fetchUserDetails();
  }, [instance, accounts, dispatch]);

  return null;
};

export default UserFetcher;
