import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faSpinner, faTimes, faBan } from '@fortawesome/free-solid-svg-icons';
import { motion } from 'framer-motion';
import './SearchEmails.css';

const SearchEmails = ({ onSearch, onSearchCancel, selectedMailbox }) => {
  const [query, setQuery] = useState('');
  const [searchField, setSearchField] = useState('subject');
  const [isSearching, setIsSearching] = useState(false);
  const [noResults, setNoResults] = useState(false);
  const [hasResults, setHasResults] = useState(false);

  useEffect(() => {
    if (selectedMailbox === 'Support') {
      setSearchField('categories');
    } else {
      setSearchField('subject');
    }
  }, [selectedMailbox]);

  const handleSearch = () => {
    if (query.trim() === '') {
      setNoResults(true);
      setTimeout(() => {
        setNoResults(false);
      }, 800);
      return;
    }

    setIsSearching(true);
    onSearch(query, searchField, (isEmpty, hasResults) => {
      setIsSearching(false);
      setHasResults(hasResults);

      if (isEmpty) {
        setNoResults(true);
        setTimeout(() => {
          setNoResults(false);
        }, 800);
      }
    });
  };

  const handleNewCategorySearch = () => {
    setIsSearching(true);
    onSearch('New', 'categories', (isEmpty, hasResults) => {
      setIsSearching(false);
      setHasResults(hasResults);

      if (isEmpty) {
        setNoResults(true);
        setTimeout(() => {
          setNoResults(false);
        }, 800);
      }
    });
  };


  const handleOngoingSearch = () => {
    setIsSearching(true);
    onSearch('Processing by', 'categories', (isEmpty, hasResults) => {
      setIsSearching(false);
      setHasResults(hasResults);

      if (isEmpty) {
        setNoResults(true);
        setTimeout(() => {
          setNoResults(false);
        }, 800);
      }
    });
  };
  
  const handleDoneSearch = () => {
    setIsSearching(true);
    onSearch('Finished by', 'categories', (isEmpty, hasResults) => {
      setIsSearching(false);
      setHasResults(hasResults);

      if (isEmpty) {
        setNoResults(true);
        setTimeout(() => {
          setNoResults(false);
        }, 800);
      }
    });
  };

  const handleCancel = () => {
    setIsSearching(false);
    setQuery('');
    onSearchCancel();
    setHasResults(false);
  };

  const isTagsAndSupport = selectedMailbox === 'Support' && searchField === 'categories';

  return (
    <motion.div 
      className="ea2-se-search-emails-bar"
    >
      <motion.div 
        className="ea2-se-search-container" 
        initial={{ opacity: 0, x: 0 }}
        animate={{ opacity: 1, x: 0 }}
        exit={{ opacity: 0, x: 0 }}
      >
        {/* 下拉菜单 */}
        <div className="ea2-se-search-options">
          <select
            value={searchField}
            onChange={(e) => setSearchField(e.target.value)}
            className="ea2-se-search-select"
          >
            <option value="subject">Subject</option>
            <option value="categories">Tags</option>
            <option value="from">From</option>
            <option value="to">To</option>
            <option value="cc">Cc</option>
          </select>
        </div>
  
        {/* 根据条件显示New或者Follow-Up或者搜索 */}
        {isTagsAndSupport ? (
          <>
            <button
              onClick={handleNewCategorySearch}
              className="ea2-se-search-button ea2-se-new-button" // 新增样式类别
              disabled={isSearching}
            >
              {isSearching ? (
                <FontAwesomeIcon icon={faSpinner} spin className="ea2-se-search-icon" />
              ) : noResults ? (
                <motion.div
                  initial={{ x: 0 }}
                  animate={{ x: [0, -5, 5, -5, 5, 0] }} // 晃动效果
                  transition={{ duration: 0.8 }}
                >
                  <FontAwesomeIcon icon={faBan} className="ea2-se-search-icon" />
                </motion.div>
              ) : (
                'New'
              )}
            </button>
            <button
              onClick={handleOngoingSearch}
              className="ea2-se-search-button ea2-se-ongoing-button" // 新增样式类别
              disabled={isSearching}
            >
              {isSearching ? (
                <FontAwesomeIcon icon={faSpinner} spin className="ea2-se-search-icon" />
              ) : noResults ? (
                <motion.div
                  initial={{ x: 0 }}
                  animate={{ x: [0, -5, 5, -5, 5, 0] }} // 晃动效果
                  transition={{ duration: 0.8 }}
                >
                  <FontAwesomeIcon icon={faBan} className="ea2-se-search-icon" />
                </motion.div>
              ) : (
                'On Going'
              )}
            </button>
            <button
              onClick={handleDoneSearch}
              className="ea2-se-search-button ea2-se-done-button" // 新增样式类别
              disabled={isSearching}
            >
              {isSearching ? (
                <FontAwesomeIcon icon={faSpinner} spin className="ea2-se-search-icon" />
              ) : noResults ? (
                <motion.div
                  initial={{ x: 0 }}
                  animate={{ x: [0, -5, 5, -5, 5, 0] }} // 晃动效果
                  transition={{ duration: 0.8 }}
                >
                  <FontAwesomeIcon icon={faBan} className="ea2-se-search-icon" />
                </motion.div>
              ) : (
                'Done'
              )}
            </button>
            
          </>
        ) : (
          <>
            <input
              type="text"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              placeholder={`Search ${searchField}...`}
              className="ea2-se-search-input"
            />
            <button
              onClick={handleSearch}
              className="ea2-se-search-button"
              disabled={isSearching}
            >
              {isSearching ? (
                <FontAwesomeIcon icon={faSpinner} spin className="ea2-se-search-icon" />
              ) : noResults ? (
                <motion.div
                  initial={{ x: 0 }}
                  animate={{ x: [0, -5, 5, -5, 5, 0] }} // 晃动效果
                  transition={{ duration: 0.8 }}
                >
                  <FontAwesomeIcon icon={faBan} className="ea2-se-search-icon" />
                </motion.div>
              ) : (
                <FontAwesomeIcon icon={faSearch} className="ea2-se-search-icon" />
              )}
            </button>
          </>
        )}
      </motion.div>
      
      {/* 取消按钮 */}
      {hasResults && (
        <motion.button
          onClick={handleCancel}
          className="ea2-se-cancel-button"
          initial={{ opacity: 0, x: 0 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: 0 }}
        >
          <FontAwesomeIcon icon={faTimes} className="ea2-se-cancel-icon" />
        </motion.button>
      )}
    </motion.div>
  );
};

export default SearchEmails;
