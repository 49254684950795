import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlus, faReply, faTimes, faArrowDown, faPaperclip, faPaperPlane, faCheck, faSync, faArrowLeft, faTag
} from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import './EmailControlBar.css';

const EmailControlBar = ({ composeEmailVisible, onMobileClickBack, onNewEmail, onReplyEmail, onCloseComposeEmail, onAttachmentClick, onSendEmail, sendStatus, attachmentCount, selectedEmail, onToggleManualTag }) => {
  const [currentIcon, setCurrentIcon] = useState(faPaperPlane);
  const [iconColor, setIconColor] = useState('inherit');
  const [hoveredButton, setHoveredButton] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    let timer;
    if (sendStatus === 'sending') {
      setCurrentIcon(faSync);
      setIconColor('inherit');
    } else if (sendStatus === 'success') {
      setCurrentIcon(faCheck);
      setIconColor('green');
      timer = setTimeout(() => {
        setCurrentIcon(faPaperPlane);
        setIconColor('inherit');
      }, 2000);
    } else if (sendStatus === 'error') {
      setCurrentIcon(faTimes);
      setIconColor('red');
      timer = setTimeout(() => {
        setCurrentIcon(faPaperPlane);
        setIconColor('inherit');
      }, 2000);
    } else if (sendStatus === 'idle') {
      setCurrentIcon(faPaperPlane);
      setIconColor('inherit');
    }

    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [sendStatus]);


  const buttonNames = {
    back: 'Back',
    close: 'Close',
    newEmail: 'New Email',
    reply: 'Reply',
    attachment: 'Attachment',
    send: 'Send',
    tag: 'Tag',
  };

  return (
    <div className="ecb-email-control-bar">
      <div className="ecb-control-group ecb-left-group">
        {!composeEmailVisible && (
          <button 
            className="ecb-control-button"
            onMouseEnter={() => setHoveredButton('back')}
            onMouseLeave={() => setHoveredButton(null)}
            onClick={onMobileClickBack}
          >
            <FontAwesomeIcon icon={faArrowLeft} />
            {hoveredButton === 'back' && <span className="ecb-tooltip">{buttonNames.back}</span>}
          </button>
        )}
        {composeEmailVisible && (
          <button 
            className="ecb-control-button"
            onMouseEnter={() => setHoveredButton('close')}
            onMouseLeave={() => setHoveredButton(null)}
            onClick={onCloseComposeEmail}
          >
            <FontAwesomeIcon icon={faArrowDown} />
            {hoveredButton === 'close' && <span className="ecb-tooltip">{buttonNames.close}</span>}
          </button>
        )}
      </div>
      <div className="ecb-control-group ecb-center-group">
        {!composeEmailVisible && (
          <>
            <button 
              className="ecb-control-button"
              onMouseEnter={() => setHoveredButton('newEmail')}
              onMouseLeave={() => setHoveredButton(null)}
              onClick={onNewEmail}
            >
              <FontAwesomeIcon icon={faPlus} />
              {hoveredButton === 'newEmail' && <span className="ecb-tooltip">{buttonNames.newEmail}</span>}
            </button>
            <button 
              className={`ecb-control-button ${!selectedEmail ? 'disabled' : ''}`} 
              onMouseEnter={() => setHoveredButton(selectedEmail ? 'reply' : null)}
              onMouseLeave={() => setHoveredButton(null)}
              onClick={selectedEmail ? onReplyEmail : null}
            >
              <FontAwesomeIcon icon={faReply} />
              {hoveredButton === 'reply' && <span className="ecb-tooltip">{buttonNames.reply}</span>}
            </button>
          </>
        )}
        {composeEmailVisible && (
          <button 
            className="ecb-control-button"
            onMouseEnter={() => setHoveredButton('attachment')}
            onMouseLeave={() => setHoveredButton(null)}
            onClick={onAttachmentClick}
          >
            <FontAwesomeIcon icon={faPaperclip} />
            {attachmentCount > 0 && (
              <span className="ecb-attachment-count-bubble">{attachmentCount}</span>
            )}
            {hoveredButton === 'attachment' && <span className="ecb-tooltip">{buttonNames.attachment}</span>}
          </button>
        )}
      </div>
      <div className="ecb-control-group ecb-right-group">
        {!composeEmailVisible ? (
          <>
            <button 
              className={`ecb-control-button ${!selectedEmail ? 'disabled' : ''}`} 
              onMouseEnter={() => setHoveredButton(selectedEmail ? 'tag' : null)}
              onMouseLeave={() => setHoveredButton(null)}
              onClick={selectedEmail ? onToggleManualTag : null}
            >
              <FontAwesomeIcon icon={faTag} />
              {hoveredButton === 'tag' && <span className="ecb-tooltip">{buttonNames.tag}</span>}
            </button>
          </>
        ) : (
          <button 
            className="ecb-control-button"
            onMouseEnter={() => setHoveredButton('send')}
            onMouseLeave={() => setHoveredButton(null)}
            onClick={onSendEmail}
          >
            <FontAwesomeIcon icon={currentIcon} color={iconColor} spin={sendStatus === 'sending'} />
            {hoveredButton === 'send' && <span className="ecb-tooltip">{buttonNames.send}</span>}
          </button>
        )}
      </div>
    </div>
  );
};

export default EmailControlBar;
