import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faFilePdf, faEnvelope, faFileImage, faFileAlt, 
  faFileWord, faFileExcel, faFileArchive, 
  faFileVideo, faFileAudio, faFile 
} from '@fortawesome/free-solid-svg-icons';
import './EmailDetailAttachments.css';

const EmailDetailAttachments = ({ attachments, onPreview }) => {

  const getIconByFileType = (contentType) => {
    if (!contentType) return faFile;

    if (contentType.includes('pdf')) return faFilePdf;
    if (contentType.includes('image') || contentType.includes('png') || contentType.includes('jpg') || contentType.includes('jpeg') || contentType.includes('gif')) return faFileImage;
    if (contentType.includes('text')) return faFileAlt;
    if (contentType.includes('word')) return faFileWord;
    if (contentType.includes('excel')) return faFileExcel;
    if (contentType.includes('zip') || contentType.includes('rar')) return faFileArchive;
    if (contentType.includes('video')) return faFileVideo;
    if (contentType.includes('audio')) return faFileAudio;
    if (contentType.includes('message/rfc822')) return faEnvelope;
    return faFile;
  };

  return (
    <div className="eda-attachments">
      {attachments.map((attachment, index) => (
        <div
          key={attachment.id}
          className="eda-attachment"
          onClick={() => onPreview(index)}
        >
          <FontAwesomeIcon icon={getIconByFileType(attachment.contentType)} className="eda-attachment-icon" />
          <span className="eda-attachment-name">{attachment.name}</span>
        </div>
      ))}
    </div>
  );
};

export default EmailDetailAttachments;
