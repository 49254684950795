import { configureStore, createSlice } from '@reduxjs/toolkit';

const savedTheme = localStorage.getItem('theme') || 'light';

const userSlice = createSlice({
  name: 'user',
  initialState: {
    id: null,
    name: null,
    avatar: '/default-avatar.png',
    email: null,
    mailboxes: [],
  },
  reducers: {
    setUser(state, action) {
      state.id = action.payload.id;
      state.name = action.payload.displayName;
      state.email = action.payload.email;
    },
    setAvatar(state, action) {
      state.avatar = action.payload;
    },
    setMailboxes(state, action) {
      state.mailboxes = action.payload;
    },
  },
});

export const { setUser, setAvatar, setMailboxes } = userSlice.actions;

const themeSlice = createSlice({
  name: 'theme',
  initialState: savedTheme,
  reducers: {
    toggleTheme(state) {
      const newTheme = state === 'light' ? 'dark' : 'light';
      localStorage.setItem('theme', newTheme);
      return newTheme;
    },
    setTheme(state, action) {
      localStorage.setItem('theme', action.payload);
      return action.payload;
    }
  }
});

export const { toggleTheme, setTheme } = themeSlice.actions;

const msgboxLanguageSlice = createSlice({
  name: 'msgbox-language',
  initialState: 'en', // 默认语言为英文
  reducers: {
    setLanguage(state, action) {
      return action.payload; // 直接更新语言
    }
  }
});

export const { setLanguage } = msgboxLanguageSlice.actions;

const store = configureStore({
  reducer: {
    user: userSlice.reducer,
    theme: themeSlice.reducer,
    msgboxLanguage: msgboxLanguageSlice.reducer, // 添加 msgbox-language slice
  },
});

export default store;
