import { Client } from '@microsoft/microsoft-graph-client';

const mailboxRules = {
  'support-pool@fraba.com': {
    applyRule: async (email, userName, instance, selectedMailbox, accounts) => {
      // 正则表达式匹配类似 ID2405121102 的字符串 (YYMMDDHHMM)
      const idPattern = /ID\d{10}/;

      if (!idPattern.test(email.subject)) {
        const newSubject = `${getFormattedTimestamp()} Re: ${email.subject}`;
        return newSubject;
      }
      return `Re: ${email.subject}`;
    },
    updateCategory: async (emailId, newCategory, instance, selectedMailbox, accounts) => {
      try {
        const account = accounts[0];
        const response = await instance.acquireTokenSilent({
          scopes: ['Mail.ReadWrite'],
          account: account,
        });

        const client = Client.init({
          authProvider: (done) => {
            done(null, response.accessToken);
          },
        });

        const message = await client.api(`/users/${selectedMailbox}/messages/${emailId}`).get();

        let categories = message.categories;

        // 移除 'New' 分类或以 'Processing by ' 开头的分类
        categories = categories.filter(category => category !== 'New' && !category.startsWith('Processing by '));

        // 添加新分类
        if (!categories.includes(newCategory)) {
          categories.push(newCategory);
        }

        await client.api(`/users/${selectedMailbox}/messages/${emailId}`).update({
          categories: categories
        });

      } catch (error) {
        console.error('Failed to update categories:', error);
      }
    }
  }
};

const getFormattedTimestamp = () => {
  const now = new Date();
  const gmtTime = new Date(now.getTime() + now.getTimezoneOffset() * 60000);
  const year = gmtTime.getFullYear().toString().slice(2);
  const month = (gmtTime.getMonth() + 1).toString().padStart(2, '0');
  const day = gmtTime.getDate().toString().padStart(2, '0');
  const hours = gmtTime.getHours().toString().padStart(2, '0');
  const minutes = gmtTime.getMinutes().toString().padStart(2, '0');
  return `ID${year}${month}${day}${hours}${minutes}`;
};

export default mailboxRules;
