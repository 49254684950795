export const filterEmails = (emails, currentTag) => {
  switch (currentTag) {
    case 'New':
      // 过滤逻辑：包含 "New" 标签，并且不包含以 "Assigned to" 或 "Finished by" 开头的标签
      return emails.filter(email => {
        const { categories } = email;

        // 检查是否包含 "New" 标签
        const hasRelevantCategory = categories && categories.includes('New');

        // 排除包含 "Assigned to" 或 "Finished by" 开头的标签的邮件
        const hasDisallowedCategories = categories && categories.some(category => 
          category.startsWith('Assigned to') || category.startsWith('Finished by')
        );

        // 保留包含 "New" 标签，且没有不允许的标签的邮件
        return hasRelevantCategory && !hasDisallowedCategories;
      });

    case 'Processing by':
      // 过滤逻辑：包含 "Processing by" 或 "Follow-Up" 标签，并且不包含以 "Assigned to" 或 "Finished by" 开头的标签
      return emails.filter(email => {
        const { categories } = email;

        // 检查是否包含 "Processing by" 或 "Follow-Up" 标签
        const hasRelevantCategory = categories && (categories.some(category => 
          category.startsWith('Processing by')) || categories.includes('Follow-Up'));

        // 排除包含 "Assigned to" 或 "Finished by" 开头的标签的邮件
        const hasDisallowedCategories = categories && categories.some(category => 
          category.startsWith('Assigned to') || category.startsWith('Finished by')
        );

        // 保留包含 "Processing by" 或 "Follow-Up" 标签，且没有不允许的标签的邮件
        return hasRelevantCategory && !hasDisallowedCategories;
      });

    default:
      return emails; // 如果没有特定的过滤逻辑，返回所有邮件
  }
};
