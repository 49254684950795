const translations = {
  en: {
    'home-title': "Message Box",
    'home-nameLabel': "Name",
    'home-emailLabel': "Email",
    'home-continue': "Continue",
    'home-languageLabel': "Language",
    'entry-welcomeMessage': "Welcome {name}, what can we help you with?",
    'entry-option-inquiry': 'Business Inquiry',
    'entry-option-support': 'Support Request',
    'entry-option-documentation': 'Documentation Correction',
    'entry-option-typekey': 'Typekey Tool',
    'entry-description-inquiry': 'Get product quotes and get in touch with the sales team.',
    'entry-description-support': 'Get product support and get in touch with the product support team.',
    'entry-description-documentation': 'Provide feedback on document corrections.',
    'entry-description-typekey': 'Explore the specific meaning of Typekey.',
    'entry-continue': 'Continue',
    'preinfo-collector-title': "Before you continue, we would like to know some information about your company.",
    'preinfo-collector-companyName': "Company Name",
    'preinfo-collector-industry': "Industry",
    'preinfo-collector-countryCity': "Country and City",
    'preinfo-collector-zipCode': "Zip Code",
    'preinfo-collector-continue': "Continue"
  },
  zh: {
    'home-title': "留言框",
    'home-nameLabel': "姓名",
    'home-emailLabel': "电子邮件",
    'home-continue': "继续",
    'home-languageLabel': "语言",
    'entry-welcomeMessage': "欢迎 {name}, 我们能帮你什么?",
    'entry-option-inquiry': '业务询价',
    'entry-option-support': '支持请求',
    'entry-option-documentation': '文稿更正',
    'entry-option-typekey': 'Typekey工具',
    'entry-description-inquiry': '获取产品报价并与销售团队联系。',
    'entry-description-support': '获取产品支持并与产品支持团队联系。',
    'entry-description-documentation': '提供文档更正反馈。',
    'entry-description-typekey': '探索Typekey的具体含义。',
    'entry-continue': '继续',
    'preinfo-collector-title': "在您继续之前，我们希望得知您的公司信息。",
    'preinfo-collector-companyName': "公司名称",
    'preinfo-collector-industry': "公司行业",
    'preinfo-collector-countryCity': "国家和城市",
    'preinfo-collector-zipCode': "邮编",
    'preinfo-collector-continue': "继续"
  },
  es: {
    'home-title': "Buzón de Mensajes",
    'home-nameLabel': "Nombre",
    'home-emailLabel': "Correo Electrónico",
    'home-continue': "Continuar",
    'home-languageLabel': "Idioma",
    'entry-welcomeMessage': "Bienvenido {name}, ¿en qué podemos ayudarte?",
    'entry-option-inquiry': 'Consulta de Negocios',
    'entry-option-support': 'Solicitud de Soporte',
    'entry-option-documentation': 'Corrección de Documentación',
    'entry-option-typekey': 'Herramienta Typekey',
    'entry-description-inquiry': 'Obtén cotizaciones de productos y contacta al equipo de ventas.',
    'entry-description-support': 'Obtén soporte de productos y contacta al equipo de soporte.',
    'entry-description-documentation': 'Proporcione comentarios sobre correcciones de documentos.',
    'entry-description-typekey': 'Explora el significado específico de Typekey.',
    'entry-continue': 'Continuar',
    'preinfo-collector-title': "Antes de continuar, nos gustaría conocer algunos detalles sobre su empresa.",
    'preinfo-collector-companyName': "Nombre de la Empresa",
    'preinfo-collector-industry': "Industria",
    'preinfo-collector-countryCity': "País y Ciudad",
    'preinfo-collector-zipCode': "Código Postal",
    'preinfo-collector-continue': "Continuar"
  },
  fr: {
    'home-title': "Boîte de Messages",
    'home-nameLabel': "Nom",
    'home-emailLabel': "E-mail",
    'home-continue': "Continuer",
    'home-languageLabel': "Langue",
    'entry-welcomeMessage': "Bienvenue {name}, comment pouvons-nous vous aider?",
    'entry-option-inquiry': 'Demande de Devis',
    'entry-option-support': 'Demande de Support',
    'entry-option-documentation': 'Correction de Documentation',
    'entry-option-typekey': 'Outil Typekey',
    'entry-description-inquiry': 'Obtenez des devis produits et contactez l\'équipe commerciale.',
    'entry-description-support': 'Obtenez du support produit et contactez l\'équipe de support.',
    'entry-description-documentation': 'Fournissez des retours sur les corrections de documentation.',
    'entry-description-typekey': 'Explorez la signification spécifique de Typekey.',
    'entry-continue': 'Continuer',
    'preinfo-collector-title': "Avant de continuer, nous aimerions en savoir plus sur votre entreprise.",
    'preinfo-collector-companyName': "Nom de l'Entreprise",
    'preinfo-collector-industry': "Secteur",
    'preinfo-collector-countryCity': "Pays et Ville",
    'preinfo-collector-zipCode': "Code Postal",
    'preinfo-collector-continue': "Continuer"
  },
  de: {
    'home-title': "Nachrichtenbox",
    'home-nameLabel': "Name",
    'home-emailLabel': "E-Mail",
    'home-continue': "Weiter",
    'home-languageLabel': "Sprache",
    'entry-welcomeMessage': "Willkommen {name}, wie können wir Ihnen helfen?",
    'entry-option-inquiry': 'Geschäftsanfrage',
    'entry-option-support': 'Supportanfrage',
    'entry-option-documentation': 'Dokumentationskorrektur',
    'entry-option-typekey': 'Typekey Tool',
    'entry-description-inquiry': 'Holen Sie sich Produktangebote und kontaktieren Sie das Vertriebsteam.',
    'entry-description-support': 'Erhalten Sie Produktsupport und kontaktieren Sie das Support-Team.',
    'entry-description-documentation': 'Geben Sie Feedback zu Dokumentkorrekturen.',
    'entry-description-typekey': 'Erkunden Sie die spezifische Bedeutung von Typekey.',
    'entry-continue': 'Weiter',
    'preinfo-collector-title': "Bevor Sie fortfahren, möchten wir einige Informationen über Ihr Unternehmen wissen.",
    'preinfo-collector-companyName': "Firmenname",
    'preinfo-collector-industry': "Branche",
    'preinfo-collector-countryCity': "Land und Stadt",
    'preinfo-collector-zipCode': "Postleitzahl",
    'preinfo-collector-continue': "Weiter"
  },
  pt: {
    'home-title': "Caixa de Mensagens",
    'home-nameLabel': "Nome",
    'home-emailLabel': "Email",
    'home-continue': "Continuar",
    'home-languageLabel': "Idioma",
    'entry-welcomeMessage': "Bem-vindo {name}, como podemos ajudá-lo?",
    'entry-option-inquiry': 'Consulta de Negócios',
    'entry-option-support': 'Solicitação de Suporte',
    'entry-option-documentation': 'Correção de Documentação',
    'entry-option-typekey': 'Ferramenta Typekey',
    'entry-description-inquiry': 'Obtenha cotações de produtos e entre em contato com a equipe de vendas.',
    'entry-description-support': 'Obtenha suporte ao produto e entre em contato com a equipe de suporte.',
    'entry-description-documentation': 'Forneça feedback sobre correções de documentos.',
    'entry-description-typekey': 'Explore o significado específico de Typekey.',
    'entry-continue': 'Continuar',
    'preinfo-collector-title': "Antes de continuar, gostaríamos de saber algumas informações sobre a sua empresa.",
    'preinfo-collector-companyName': "Nome da Empresa",
    'preinfo-collector-industry': "Indústria",
    'preinfo-collector-countryCity': "País e Cidade",
    'preinfo-collector-zipCode': "Código Postal",
    'preinfo-collector-continue': "Continuar"
  },
  ru: {
    'home-title': "Ящик сообщений",
    'home-nameLabel': "Имя",
    'home-emailLabel': "Эл. почта",
    'home-continue': "Продолжить",
    'home-languageLabel': "Язык",
    'entry-welcomeMessage': "Добро пожаловать {name}, чем мы можем вам помочь?",
    'entry-option-inquiry': 'Запрос на бизнес',
    'entry-option-support': 'Запрос на поддержку',
    'entry-option-documentation': 'Коррекция документации',
    'entry-option-typekey': 'Инструмент Typekey',
    'entry-description-inquiry': 'Получите предложения по продукту и свяжитесь с командой продаж.',
    'entry-description-support': 'Получите поддержку по продукту и свяжитесь с командой поддержки.',
    'entry-description-documentation': 'Предоставьте отзыв по исправлениям в документации.',
    'entry-description-typekey': 'Изучите конкретное значение Typekey.',
    'entry-continue': 'Продолжить',
    'preinfo-collector-title': "Прежде чем продолжить, мы хотели бы узнать некоторые данные о вашей компании.",
    'preinfo-collector-companyName': "Название компании",
    'preinfo-collector-industry': "Отрасль",
    'preinfo-collector-countryCity': "Страна и Город",
    'preinfo-collector-zipCode': "Почтовый индекс",
    'preinfo-collector-continue': "Продолжить"
  },
  ar: {
    'home-title': "صندوق الرسائل",
    'home-nameLabel': "الاسم",
    'home-emailLabel': "البريد الإلكتروني",
    'home-continue': "استمرار",
    'home-languageLabel': "اللغة",
    'entry-welcomeMessage': "مرحبًا {name}، كيف يمكننا مساعدتك؟",
    'entry-option-inquiry': 'استفسار تجاري',
    'entry-option-support': 'طلب الدعم',
    'entry-option-documentation': 'تصحيح الوثائق',
    'entry-option-typekey': 'أداة Typekey',
    'entry-description-inquiry': 'احصل على عروض الأسعار واتصل بفريق المبيعات.',
    'entry-description-support': 'احصل على دعم المنتج واتصل بفريق الدعم.',
    'entry-description-documentation': 'قدم ملاحظات حول تصحيح الوثائق.',
    'entry-description-typekey': 'استكشاف المعنى المحدد لـ Typekey.',
    'entry-continue': 'استمرار',
    'preinfo-collector-title': "قبل المتابعة، نود معرفة بعض المعلومات عن شركتك.",
    'preinfo-collector-companyName': "اسم الشركة",
    'preinfo-collector-industry': "الصناعة",
    'preinfo-collector-countryCity': "البلد والمدينة",
    'preinfo-collector-zipCode': "الرمز البريدي",
    'preinfo-collector-continue': "استمرار"
  },
  he: {
    'home-title': "תיבת הודעות",
    'home-nameLabel': "שם",
    'home-emailLabel': "אימייל",
    'home-continue': "המשך",
    'home-languageLabel': "שפה",
    'entry-welcomeMessage': "ברוך הבא {name}, איך אפשר לעזור לך?",
    'entry-option-inquiry': 'פניית עסקים',
    'entry-option-support': 'בקשת תמיכה',
    'entry-option-documentation': 'תיקון מסמכים',
    'entry-option-typekey': 'כלי Typekey',
    'entry-description-inquiry': 'קבל הצעות מוצר ויצור קשר עם צוות המכירות.',
    'entry-description-support': 'קבל תמיכה במוצר וצור קשר עם צוות התמיכה.',
    'entry-description-documentation': 'ספק משוב על תיקוני מסמכים.',
    'entry-description-typekey': 'חקור את המשמעות הספציפית של Typekey.',
    'entry-continue': 'המשך',
    'preinfo-collector-title': "לפני שתמשיך, נשמח לדעת מידע על החברה שלך.",
    'preinfo-collector-companyName': "שם החברה",
    'preinfo-collector-industry': "תעשייה",
    'preinfo-collector-countryCity': "מדינה ועיר",
    'preinfo-collector-zipCode': "מיקוד",
    'preinfo-collector-continue': "המשך"
  },
  ja: {
    'home-title': "メッセージボックス",
    'home-nameLabel': "名前",
    'home-emailLabel': "メール",
    'home-continue': "続行",
    'home-languageLabel': "言語",
    'entry-welcomeMessage': "{name}さん、ようこそ。どのようなお手伝いをいたしましょうか？",
    'entry-option-inquiry': 'ビジネス問い合わせ',
    'entry-option-support': 'サポートリクエスト',
    'entry-option-documentation': '文書修正',
    'entry-option-typekey': 'Typekeyツール',
    'entry-description-inquiry': '製品の見積もりを取得し、営業チームに連絡してください。',
    'entry-description-support': '製品サポートを取得し、サポートチームに連絡してください。',
    'entry-description-documentation': '文書修正に関するフィードバックを提供してください。',
    'entry-description-typekey': 'Typekeyの具体的な意味を探る。',
    'entry-continue': '続行',
    'preinfo-collector-title': "続行する前に、貴社についていくつか教えてください。",
    'preinfo-collector-companyName': "会社名",
    'preinfo-collector-industry': "業界",
    'preinfo-collector-countryCity': "国と都市",
    'preinfo-collector-zipCode': "郵便番号",
    'preinfo-collector-continue': "続行"
  },
  ko: {
    'home-title': "메시지 상자",
    'home-nameLabel': "이름",
    'home-emailLabel': "이메일",
    'home-continue': "계속",
    'home-languageLabel': "언어",
    'entry-welcomeMessage': "환영합니다 {name}, 무엇을 도와드릴까요?",
    'entry-option-inquiry': '비즈니스 문의',
    'entry-option-support': '지원 요청',
    'entry-option-documentation': '문서 수정',
    'entry-option-typekey': 'Typekey 도구',
    'entry-description-inquiry': '제품 견적을 받고 영업팀과 연락하십시오.',
    'entry-description-support': '제품 지원을 받고 지원팀과 연락하십시오.',
    'entry-description-documentation': '문서 수정에 대한 피드백을 제공하십시오.',
    'entry-description-typekey': 'Typekey의 구체적인 의미를 탐구하십시오.',
    'entry-continue': '계속',
    'preinfo-collector-title': "계속하기 전에 귀하의 회사 정보가 필요합니다.",
    'preinfo-collector-companyName': "회사 이름",
    'preinfo-collector-industry': "산업",
    'preinfo-collector-countryCity': "국가와 도시",
    'preinfo-collector-zipCode': "우편 번호",
    'preinfo-collector-continue': "계속"
  },
  it: {
    'home-title': "Casella di Messaggi",
    'home-nameLabel': "Nome",
    'home-emailLabel': "Email",
    'home-continue': "Continua",
    'home-languageLabel': "Lingua",
    'entry-welcomeMessage': "Benvenuto {name}, come possiamo aiutarti?",
    'entry-option-inquiry': 'Richiesta di Affari',
    'entry-option-support': 'Richiesta di Supporto',
    'entry-option-documentation': 'Correzione della Documentazione',
    'entry-option-typekey': 'Strumento Typekey',
    'entry-description-inquiry': 'Ottieni preventivi sui prodotti e contatta il team di vendita.',
    'entry-description-support': 'Ottieni assistenza per i prodotti e contatta il team di supporto.',
    'entry-description-documentation': 'Fornisci un feedback sulle correzioni della documentazione.',
    'entry-description-typekey': 'Esplora il significato specifico di Typekey.',
    'entry-continue': 'Continua',
    'preinfo-collector-title': "Prima di continuare, vorremmo sapere alcune informazioni sulla tua azienda.",
    'preinfo-collector-companyName': "Nome dell'Azienda",
    'preinfo-collector-industry': "Settore",
    'preinfo-collector-countryCity': "Paese e Città",
    'preinfo-collector-zipCode': "Codice Postale",
    'preinfo-collector-continue': "Continua",
    
  }
};

export default translations;
