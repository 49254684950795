import React, { useState, useRef, useEffect, useCallback } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import EmailItem from './email-list/EmailItem';
import './EmailsList.css';

const EmailsList = ({ emails, onSelectEmail, onLoadMore, selectedMailbox, selectedFolder, className, onWidthChange }) => {
  const [selectedEmailId, setSelectedEmailId] = useState(null);
  const [expandedEmailId, setExpandedEmailId] = useState(null);

  const observer = useRef(null);
  const emailListRef = useRef(null);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        if (entry.contentRect.width < 380) {
          onWidthChange(false);
        } else {
          onWidthChange(true);
        }
      }
    });

    if (emailListRef.current) {
      resizeObserver.observe(emailListRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, [onWidthChange]);

  const handleEmailClick = (email) => {
    setSelectedEmailId(email.id);
    onSelectEmail(email);
  };

  const handleExpandClick = (email) => {
    if (expandedEmailId === email.id) {
      setExpandedEmailId(null); // 收缩时关闭
    } else {
      setExpandedEmailId(email.id); // 展开时显示线程和主邮件副本
    }
  };

  const thirdLastEmailRef = useCallback(
    (node) => {
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          onLoadMore();
        }
      });
      if (node) observer.current.observe(node);
    },
    [onLoadMore]
  );

  return (
    <div ref={emailListRef} className={`elx-email-list ${className}`}>
      {emails.map((email, index) => {
        // 将主邮件和线程邮件合并，并按时间排序
        const allMessages = [
          { ...email, isMainEmail: true },  // 将主邮件添加到列表
          ...(email.threadMessages || [])   // 添加线程邮件
        ].sort((a, b) => new Date(b.receivedDateTime) - new Date(a.receivedDateTime));  // 按时间从新到旧排序

        return (
          <div 
            key={email.id} 
            className={`elx-email-entity ${email.threadMessages && email.threadMessages.length > 0 ? 'has-footer' : ''} ${expandedEmailId === email.id ? 'expanded' : ''}`}
            ref={emails.length === index + 8 ? thirdLastEmailRef : null}
          >
            <div 
              className={`elx-email-content ${email.id === selectedEmailId ? 'selected' : ''}`}
              onClick={() => handleEmailClick(email)}
            >
              <EmailItem 
                id={email.id}
                subject={email.subject}
                from={email.from}
                receivedDateTime={email.receivedDateTime}
                toRecipients={email.toRecipients}
                ccRecipients={email.ccRecipients}
                isRead={email.isRead}
                categories={email.categories}
                hasAttachments={email.hasAttachments}
                importance={email.importance}
                bodyPreview={email.bodyPreview}
                selectedMailbox={selectedMailbox}
                selectedFolder={selectedFolder}
              />
            </div>
            {email.threadMessages && email.threadMessages.length > 0 && (
              <div className="elx-email-footer">
                <button 
                  className="elx-expand-button" 
                  onClick={(e) => { e.stopPropagation(); handleExpandClick(email); }}
                >
                  <span className="elx-thread-count">{email.threadMessages.length}</span>
                  <FontAwesomeIcon icon={expandedEmailId === email.id ? faChevronUp : faChevronDown} />
                </button>
              </div>
            )}
            <AnimatePresence>
              {expandedEmailId === email.id && (
                <motion.div 
                  className="elx-thread-messages"
                  initial={{ height: 0, opacity: 0 }}
                  animate={{ height: 'auto', opacity: 1 }}
                  exit={{ height: 0, opacity: 0 }}
                >
                  {allMessages.map((message) => (
                    <div 
                      key={message.id} 
                      className={`elx-thread-message ${message.id === selectedEmailId ? 'selected' : ''}`}
                      onClick={(e) => { e.stopPropagation(); handleEmailClick(message); }}
                    >
                      <EmailItem 
                        id={message.id}
                        subject={message.subject}
                        from={message.from}
                        receivedDateTime={message.receivedDateTime}
                        toRecipients={message.toRecipients}
                        ccRecipients={message.ccRecipients}
                        isRead={message.isRead}
                        categories={message.categories}
                        hasAttachments={message.hasAttachments}
                        importance={message.importance}
                        bodyPreview={message.bodyPreview}
                        selectedMailbox={selectedMailbox}
                        selectedFolder={selectedFolder}
                      />
                    </div>
                  ))}
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        );
      })}
    </div>
  );
};

export default EmailsList;
