import { useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { Client } from '@microsoft/microsoft-graph-client';

const EmailTaggerSupport = ({ emails, selectedFolder }) => {
  const { instance, accounts } = useMsal();
  const [processedEmails, setProcessedEmails] = useState(new Set()); // 存储已经处理过的邮件ID
  const [emailETags, setEmailETags] = useState({}); // 存储每个邮件的 ETag

  useEffect(() => {
    const batchTagEmails = async () => {
      const response = await instance.acquireTokenSilent({
        scopes: ['Mail.ReadWrite'],
        account: accounts[0],
      });

      const client = Client.init({
        authProvider: (done) => {
          done(null, response.accessToken);
        },
      });

      try {
        const emailsToUpdate = [];

        for (const email of emails) {

          // 如果该邮件已经处理过，或者 ETag 未变，跳过更新
          if (processedEmails.has(email.id) && emailETags[email.id] === email.eTag) {
            continue;
          }

          const allMessages = [email, ...(email.threadMessages || [])];
          let categoriesToUpdate = new Set(email.categories || []);
          let assignedTo = null;

          // 查找 "Assigned to..." 标签
          for (const message of allMessages) {
            const assignedRegex = /Assigned to (.+)/;
            const assignedMatch = message.categories?.find(category => assignedRegex.test(category));

            if (assignedMatch) {
              assignedTo = assignedMatch.match(assignedRegex)[1];
              break;
            }
          }

          if (assignedTo) {
            categoriesToUpdate.add(`SR belongs to ${assignedTo}`);
          }

          // 根据线程情况添加 "New" 或 "Follow-Up" 标签
          if (!email.threadMessages || email.threadMessages.length === 0) {
            categoriesToUpdate.delete("Follow-Up");
            categoriesToUpdate.add("New");
          } else {
            categoriesToUpdate.delete("New");
            categoriesToUpdate.add("Follow-Up");

            for (const threadMessage of email.threadMessages) {
              const updatedCategories = threadMessage.categories?.filter(
                category => category !== "New" && category !== "Follow-Up"
              );

              if (updatedCategories && updatedCategories.length !== threadMessage.categories?.length) {
                emailsToUpdate.push({
                  id: threadMessage.id,
                  categories: updatedCategories,
                });
              }
            }
          }

          // 检查当前标签是否已经符合规则
          if (new Set(email.categories).size === categoriesToUpdate.size &&
            [...new Set(email.categories)].every(cat => categoriesToUpdate.has(cat))) {
            continue; // 如果标签已经符合规则，跳过更新
          }

          // 如果有新的标签，加入更新列表
          if (categoriesToUpdate.size > (email.categories || []).length) {
            emailsToUpdate.push({
              id: email.id,
              categories: Array.from(categoriesToUpdate),
            });

            // 将该邮件标记为已处理，并存储 ETag
            setProcessedEmails((prevProcessed) => new Set(prevProcessed).add(email.id));
            setEmailETags((prevETags) => ({ ...prevETags, [email.id]: email.eTag }));
          }
        }

        if (emailsToUpdate.length > 0) {
          const batchSize = 20;
          for (let i = 0; i < emailsToUpdate.length; i += batchSize) {
            const batchRequests = emailsToUpdate.slice(i, i + batchSize).map((email, index) => ({
              id: `request-${i}-${index}`,
              method: 'PATCH',
              url: `/users/support-pool@fraba.com/messages/${email.id}`,
              body: {
                categories: email.categories,
              },
              headers: {
                'Content-Type': 'application/json',
                'If-Match': emailETags[email.id], // 使用 ETag
              },
            }));

            const batchResponse = await client.api('/$batch').post({ requests: batchRequests });
            console.log('Batch tag update response:', batchResponse);
          }
        }

      } catch (error) {
        console.error('Error in batch tagging emails:', error);
      }
    };

    if (emails && emails.length > 0 && selectedFolder) {
      batchTagEmails();
    }
  }, [emails, accounts, instance, selectedFolder, processedEmails, emailETags]);

  return null;
};

export default EmailTaggerSupport;
