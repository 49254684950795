import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { useMsal } from '@azure/msal-react';
import { Client } from '@microsoft/microsoft-graph-client';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faIdBadge, faClipboardCheck, faSpinner } from '@fortawesome/free-solid-svg-icons';
import './EmailControlManualTag.css';

const EmailControlManualTag = ({ selectedMailbox, selectedFolder, selectedEmail }) => {
  const { instance, accounts } = useMsal();
  const userName = useSelector(state => state.user.name);
  const [isLoading, setIsLoading] = useState(false);
  const [taggedByCurrentUser, setTaggedByCurrentUser] = useState(false);
  const [finishedByCurrentUser, setFinishedByCurrentUser] = useState(false);
  const [taggedByOtherUser, setTaggedByOtherUser] = useState(false);
  const [otherUserName, setOtherUserName] = useState('');

  useEffect(() => {
    if (selectedEmail) {
      checkEmailTagStatus();
    }
  }, [selectedEmail]);

  const checkEmailTagStatus = async () => {
    if (!selectedEmail || !userName) return;

    setIsLoading(true);

    const account = accounts[0];
    const response = await instance.acquireTokenSilent({
      scopes: ['Mail.ReadWrite'],
      account: account,
    });

    const client = Client.init({
      authProvider: (done) => {
        done(null, response.accessToken);
      },
    });

    try {
      const userMailbox = selectedMailbox === 'Support' 
        ? 'support-pool@fraba.com' 
        : (selectedMailbox === 'Personal' ? userName : selectedMailbox);

      const message = await client.api(`/users/${userMailbox}/messages/${selectedEmail.id}`).get();
      const currentCategories = message.categories || [];
      const processingCategory = `Processing by ${userName}`;
      const finishedCategory = `Finished by ${userName}`;

      const otherProcessingCategory = currentCategories.find(category => category.startsWith('Processing by') && category !== processingCategory);
      const otherFinishedCategory = currentCategories.find(category => category.startsWith('Finished by') && category !== finishedCategory);

      if (otherProcessingCategory || otherFinishedCategory) {
        setTaggedByOtherUser(true);
        setOtherUserName(otherProcessingCategory ? otherProcessingCategory.replace('Processing by ', '') : otherFinishedCategory.replace('Finished by ', ''));
      } else {
        setTaggedByCurrentUser(currentCategories.includes(processingCategory));
        setFinishedByCurrentUser(currentCategories.includes(finishedCategory));
        setTaggedByOtherUser(false);
      }
    } catch (error) {
      console.error('Error checking email tag status:', error);
    } finally {
      setIsLoading(false);
    }
  };


  const handleTagChange = async (tagType) => {
    if (!selectedEmail || !userName || taggedByOtherUser) return;

    setIsLoading(true);

    const account = accounts[0];
    const response = await instance.acquireTokenSilent({
      scopes: ['Mail.ReadWrite'],
      account: account,
    });

    const client = Client.init({
      authProvider: (done) => {
        done(null, response.accessToken);
      },
    });

    try {
      const userMailbox = selectedMailbox === 'Support' 
        ? 'support-pool@fraba.com' 
        : (selectedMailbox === 'Personal' ? userName : selectedMailbox);

      const message = await client.api(`/users/${userMailbox}/messages/${selectedEmail.id}`).get();
      const currentCategories = message.categories || [];
      const processingCategory = `Processing by ${userName}`;
      const finishedCategory = `Finished by ${userName}`;

      let updatedCategories = [...currentCategories];
      
      if (tagType === 'Processing') {
        if (updatedCategories.includes(processingCategory)) {
          updatedCategories = updatedCategories.filter(category => category !== processingCategory);
          setTaggedByCurrentUser(false);
        } else {
          updatedCategories = updatedCategories.filter(category => category !== finishedCategory);
          updatedCategories.push(processingCategory);
          setTaggedByCurrentUser(true);
          setFinishedByCurrentUser(false);
        }
      } else if (tagType === 'Finished') {
        if (updatedCategories.includes(finishedCategory)) {
          updatedCategories = updatedCategories.filter(category => category !== finishedCategory);
          setFinishedByCurrentUser(false);
        } else {
          updatedCategories = updatedCategories.filter(category => category !== processingCategory);
          updatedCategories.push(finishedCategory);
          setFinishedByCurrentUser(true);
          setTaggedByCurrentUser(false);
        }
      }

      await client.api(`/users/${userMailbox}/messages/${selectedEmail.id}`).update({ categories: updatedCategories });
    } catch (error) {
      console.error('Error tagging email:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const emailStatusText = taggedByOtherUser
    ? `This email is tagged by ${otherUserName}. You cannot change this tag.`
    : (taggedByCurrentUser
      ? `This email is being processed by you.`
      : (finishedByCurrentUser
        ? `This email is marked as done by you.`
        : 'This email is not currently ongoing or done.'));

  return (
    <motion.div
      className="ecmt-manual-tag-container"
      initial={{ y: "100%", x: "-50%" }} 
      animate={{ y: 0, x: "-50%" }} 
      exit={{ y: "100%", x: "-50%" }} 
      transition={{ type: 'spring', stiffness: 250, damping: 30 }}
    >
      <div className="ecmt-button-group">
        <button 
          className={`ecmt-in-progress-button ${taggedByCurrentUser ? 'active' : ''}`} 
          onClick={() => handleTagChange('Processing')}
          disabled={taggedByOtherUser}
        >
          {isLoading ? (
            <FontAwesomeIcon icon={faSpinner} spin />
          ) : (
            <FontAwesomeIcon icon={faIdBadge} />
          )}
        </button>
        <button 
          className={`ecmt-finished-button ${finishedByCurrentUser ? 'active' : ''}`} 
          onClick={() => handleTagChange('Finished')}
          disabled={taggedByOtherUser}
        >
          {isLoading ? (
            <FontAwesomeIcon icon={faSpinner} spin />
          ) : (
            <FontAwesomeIcon icon={faClipboardCheck} />
          )}
        </button>
      </div>
      <span className="ecmt-in-progress-text">
        {emailStatusText}
      </span>
    </motion.div>
  );
};

export default EmailControlManualTag;
