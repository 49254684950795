import React, { useState, useRef, forwardRef, useImperativeHandle } from 'react';
import { useSelector } from 'react-redux';
import { motion, AnimatePresence } from 'framer-motion';
import ComposeMailLoader from './email-compose/ComposeMailLoader';
import ComposeMailSignatures from './email-compose/ComposeMailSignatures';
import ComposeMailSender from './email-compose/ComposeMailSender';
import ComposeMailAttachments from './email-compose/ComposeMailAttachments';
import AttachmentModal from './email-compose/AttachmentModal';
import EmailEditor from './email-compose/EmailEditor';
//import ComposeAddressBook from './email-compose/ComposeAddressBook';
import './ComposeEmail.css';

const ComposeEmail = forwardRef(({ onClose, isReplying, email, isAttachmentModalOpen, setIsAttachmentModalOpen, selectedMailbox, onSendStatusChange, setAttachmentCount }, ref) => {
  const theme = useSelector(state => state.theme);
  const userEmail = useSelector(state => state.user.email);
  const userName = useSelector((state) => state.user.name);

  const [to, setTo] = useState('');
  const [cc, setCc] = useState('');
  const [subject, setSubject] = useState('');
  const [body, setBody] = useState('');
  const [attachments, setAttachments] = useState([]);
  const [conversationId, setConversationId] = useState('');

  const [isAddressBookOpen, setIsAddressBookOpen] = useState(false);
  const [focusedField, setFocusedField] = useState('');
  const [inputPosition, setInputPosition] = useState({ top: 0, left: 0, width: 0 });

  const toInputRef = useRef(null);
  const ccInputRef = useRef(null);

  const handleAddressBookSelect = (selectedEmail) => {
    if (focusedField === 'to') {
      setTo((prev) => `${prev}${prev ? ', ' : ''}${selectedEmail}`);
    } else if (focusedField === 'cc') {
      setCc((prev) => `${prev}${prev ? ', ' : ''}${selectedEmail}`);
    }
    setIsAddressBookOpen(false);
  };

  const handleFocus = (field) => {
    setFocusedField(field);
    setIsAddressBookOpen(true);
    
    const inputRef = field === 'to' ? toInputRef.current : ccInputRef.current;
    const rect = inputRef.getBoundingClientRect();
  
    setInputPosition({ 
      top: rect.bottom + window.scrollY,  // 考虑滚动位置
      left: window.scrollX,   // 精确获取输入框的 left 位置
      width: rect.width  // 保持与输入框相同的宽度
    });
  };
  
  
  
  const mailboxAddress = selectedMailbox === 'Support' 
    ? 'support-pool@fraba.com' 
    : (selectedMailbox === 'Personal' ? userEmail : selectedMailbox);

  const handleLoadComplete = ({ content, attachments, conversationId, subject, fromAddress, ccAddresses }) => {
    const signature = ComposeMailSignatures({
      isReplying,
      email,
      selectedMailbox: mailboxAddress,
      subject,
      userName,
    });

    setBody(signature + content);
    setAttachments(attachments);
    setConversationId(conversationId);
    if (isReplying) {
      setTo(fromAddress); 
      setCc(ccAddresses.join(', '));
      setSubject(subject);
    }
  };

  const handleError = () => {
    // Handle errors here
  };

  const mailSender = ComposeMailSender({
    to,
    cc,
    subject,
    body,
    attachments,
    email,
    isReplying,
    selectedMailbox: mailboxAddress,
    conversationId,
    onSendStatusChange,
    onClose,
  });

  useImperativeHandle(ref, () => ({
    handleSend: mailSender.handleSend,
  }));

  const mailAttachments = ComposeMailAttachments({
    attachments,
    setAttachments,
  });

  return (
    <motion.div
      className="cm2-compose-email"
      initial={{ y: "100%", x: "-50%" }} 
      animate={{ y: 0, x: "-50%" }} 
      exit={{ y: "100%", x: "-50%" }} 
      transition={{ type: 'spring', stiffness: 250, damping: 30 }}
    >
      <ComposeMailLoader 
        email={email} 
        selectedMailbox={mailboxAddress} 
        isReplying={isReplying} 
        onLoadComplete={handleLoadComplete} 
        onError={handleError} 
      />
      <div className="cm2-compose-fields">
        <div className="cm2-compose-field">
          <label>SUBJECT</label>
          <input
            type="text"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
          />
        </div>
        <div className="cm2-compose-field">
        <label>TO</label>
        <input
          type="email"
          ref={toInputRef}
          value={to}
          onFocus={() => handleFocus('to')}
          onChange={(e) => setTo(e.target.value)}
        />
      </div>
      <div className="cm2-compose-field">
        <label>CC</label>
        <input
          type="text"
          ref={ccInputRef}
          value={cc}
          onFocus={() => handleFocus('cc')}
          onChange={(e) => setCc(e.target.value)}
        />
      </div>
      </div>
      <div className="cm2-compose-body">
        <EmailEditor body={body} setBody={setBody} theme={theme} />
      </div>
      
      <AttachmentModal
        isOpen={isAttachmentModalOpen}
        emailId={email?.id}
        isReplying={isReplying}
        onClose={() => setIsAttachmentModalOpen(false)}
        onFileChange={mailAttachments.handleFileChange}
        onRemoveAttachment={mailAttachments.handleRemoveAttachment}
        onAttachmentsChange={setAttachments}
        selectedMailbox={mailboxAddress}
        setAttachmentCount={setAttachmentCount}
      />
    {/*  <ComposeAddressBook 
        isOpen={isAddressBookOpen} 
        onSelectAddress={handleAddressBookSelect} 
        position={inputPosition} 
      />  */}
    </motion.div>
  );
});

export default ComposeEmail;
