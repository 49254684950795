import React from 'react';
import EmailTaggerSupport from './EmailTaggerSupportInbox';

const EmailTaggerMS = ({ emails, selectedMailbox, selectedFolder }) => {
  // 只有当 selectedMailbox 是 'Support' 且 selectedFolder.displayName 是 'Inbox' 时，才渲染 EmailTaggerSupport
  return (selectedMailbox === 'Support' && selectedFolder?.displayName === 'Inbox' && emails.length > 0) ? (
    <EmailTaggerSupport emails={emails} selectedFolder={selectedFolder} />
  ) : null;
};

export default EmailTaggerMS;
