import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { useSelector } from 'react-redux';
import translations from './translations';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane, faPaperclip } from '@fortawesome/free-solid-svg-icons';
import './MessageBoxSupport.css';
import axios from 'axios';  // 使用 axios 发送请求

const MessageBoxSupport = ({ name, selectedOption, ipCity, ipCountry, ipLanguage, selectedLanguage }) => {
  const currentLanguage = useSelector((state) => state.msgboxLanguage);
  const t = translations[currentLanguage];

  const [chatInput, setChatInput] = useState('');
  const [messages, setMessages] = useState([]);
  const [attachments, setAttachments] = useState([]);

  // 发送消息到后端的 AI 聊天接口
  const handleSendMessage = async () => {
    if (chatInput.trim()) {
      const newMessage = { text: chatInput, sender: 'user', attachments: [] };
      setMessages([...messages, newMessage]);
      setChatInput('');

      // 构建对话历史
      const history = [...messages, newMessage].map((message) => ({
        role: message.sender === 'user' ? 'user' : 'assistant',
        content: message.text,
      }));

      try {
        // 发送请求到后端
        const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/msgbox/ai-chat`, {
          messages: history,
        });

        // 获取 AI 回复内容
        const aiReply = response.data.reply;
        setMessages((prevMessages) => [...prevMessages, { text: aiReply, sender: 'ai' }]);
      } catch (error) {
        console.error('Error calling AI Chat API:', error);
        setMessages((prevMessages) => [...prevMessages, { text: 'Failed to get a response from AI.', sender: 'ai' }]);
      }
    }
  };

  // 处理输入框变化
  const handleChatInputChange = (e) => {
    setChatInput(e.target.value);
  };

  // 处理附件上传
  const handleAttachmentUpload = (e) => {
    const files = e.target.files;
    if (files.length > 0) {
      const reader = new FileReader();
      reader.onload = () => {
        const base64 = reader.result;
        setAttachments([...attachments, { fileName: files[0].name, base64 }]);

        // 在当前消息中添加附件信息
        setMessages((prevMessages) =>
          prevMessages.map((message, index) =>
            index === prevMessages.length - 1
              ? { ...message, attachments: [...message.attachments, { fileName: files[0].name, base64 }] }
              : message
          )
        );
      };
      reader.readAsDataURL(files[0]);
    }
  };

  return (
    <motion.div
      className="mbs-chat-container"
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <div className="mbs-chat-content">
        <div className="mbs-title">
          {t['support-chat-title'] || 'Chat with AI Support'}
        </div>

        <div className="mbs-messages">
          {messages.map((message, index) => (
            <div key={index} className={`mbs-message ${message.sender}`}>
              {message.text}
              {/* 显示附件 */}
              {message.attachments && message.attachments.length > 0 && (
                <div className="mbs-attachments">
                  {message.attachments.map((attachment, idx) => (
                    <div key={idx} className="mbs-attachment">
                      <a href={attachment.base64} download={attachment.fileName}>
                        {attachment.fileName}
                      </a>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>

        <div className="mbs-chat-input-group">
          <textarea
            className="mbs-chat-input"
            value={chatInput}
            onChange={handleChatInputChange}
            placeholder={t['support-chat-placeholder'] || 'Type your message here...'}
            rows="2"
          />
          <button className="mbs-send-btn" onClick={handleSendMessage}>
            <FontAwesomeIcon icon={faPaperPlane} />
          </button>
          <label className="mbs-attach-btn">
            <FontAwesomeIcon icon={faPaperclip} />
            <input type="file" onChange={handleAttachmentUpload} style={{ display: 'none' }} /> 
          </label>
        </div>
      </div>
    </motion.div>
  );
};

export default MessageBoxSupport;
