import React, { useState, useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import { Client } from '@microsoft/microsoft-graph-client';
import { useSelector } from 'react-redux';
import mailboxRules from './mailboxRules';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

const ComposeMailLoader = ({ email, selectedMailbox, isReplying, onLoadComplete, onError }) => {
  const { instance, accounts } = useMsal();
  const userName = useSelector((state) => state.user.name);
  const [loading, setLoading] = useState(false);

  const fetchEmailContent = async () => {
    try {
      setLoading(true);
      const account = accounts[0];
      const response = await instance.acquireTokenSilent({
        scopes: ['Mail.Read'],
        account: account,
      });

      const client = Client.init({
        authProvider: (done) => {
          done(null, response.accessToken);
        },
      });

      const message = await client.api(`/users/${selectedMailbox}/messages/${email.id}`).expand('attachments').get();
      const inlineAttachments = message.attachments.filter(attachment => attachment.isInline);

      let content = message.body.content;

      inlineAttachments.forEach((attachment) => {
        const contentId = `cid:${attachment.contentId}`;
        const attachmentUrl = `data:${attachment.contentType};base64,${attachment.contentBytes}`;
        content = content.replace(new RegExp(contentId, 'g'), attachmentUrl);
      });

      if (isReplying) {
        content = '<br/><br/><hr/>' + content;
      }

      const attachments = message.attachments.filter(attachment => !attachment.isInline);
      const conversationId = message.conversationId;
      const fromAddress = message.from.emailAddress.address; // 提取 From 地址
      const ccAddresses = message.ccRecipients.map(cc => cc.emailAddress.address); // 提取 Cc 地址

      if (mailboxRules[selectedMailbox] && typeof mailboxRules[selectedMailbox].applyRule === 'function') {
        console.log('Applying rule for selectedMailbox:', selectedMailbox);
      
        const newSubject = await mailboxRules[selectedMailbox].applyRule(email, userName, instance, selectedMailbox, accounts);
        
        console.log('Generated newSubject:', newSubject);
        
        onLoadComplete({ content, attachments, conversationId, subject: newSubject, fromAddress, ccAddresses });
      } else {
        const defaultSubject = `Re: ${email.subject}`;
        
        console.log('No rule found for selectedMailbox, using default subject:', defaultSubject);
        
        onLoadComplete({ content, attachments, conversationId, subject: defaultSubject, fromAddress, ccAddresses });
      }

    } catch (error) {
      console.error(error);
      onError();
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isReplying && email) {
      fetchEmailContent();
    } else {
      onLoadComplete({ content: '', attachments: [], subject: '', fromAddress: '', ccAddresses: [] });
    }
  }, [isReplying, email, instance, accounts, selectedMailbox, userName]);

  return loading ? (
    <div className="ce-loading-spinner">
      <FontAwesomeIcon icon={faSpinner} spin />
    </div>
  ) : null;
};

export default ComposeMailLoader;


