import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCommentDots } from '@fortawesome/free-solid-svg-icons';
import MessageBoxHomePage from './MessageBoxHomePage'; // 默认页面
import './MessageBoxFrame.css';

const MessageBoxFrame = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [title, setTitle] = useState('Welcome'); // 初始标题

  const toggleMessageBox = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="mbf-container">
      <motion.div
        className="mbf-expandable"
        layout
        initial={false}
        animate={isOpen ? "open" : "closed"}
        variants={{
          closed: {
            width: 60,
            height: 60,
            borderRadius: "50%",
            backgroundColor: "var(--highlight-color)",
            transition: {
              type: "spring",
              stiffness: 300,
              damping: 40
            }
          },
          open: {
            width: 350,
            height: 580,
            borderRadius: "20px",
            backgroundColor: "rgb(255,255,255)",
            transition: {
              type: "spring",
              stiffness: 300,
              damping: 35
            }
          }
        }}
        whileHover={!isOpen ? { scale: 1.05 } : undefined}
        whileTap={!isOpen ? { scale: 0.95 } : undefined}
        onClick={!isOpen ? toggleMessageBox : undefined}
        style={{ cursor: !isOpen ? "pointer" : "default" }}
      >
        <AnimatePresence>
          {!isOpen && (
            <motion.div
              className="mbf-icon-container"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <FontAwesomeIcon icon={faCommentDots} className="mbf-icon" />
            </motion.div>
          )}
        </AnimatePresence>

        <AnimatePresence>
          {isOpen && (
            <motion.div
              className="mbf-dialog"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0, transition: { duration: 0.1 } }}
              transition={{ delay: 0.1 }}
            >
              <div className="mbf-header">
                <div>{title}</div> 
                <button className="mbf-close-btn" onClick={toggleMessageBox}>×</button>
              </div>
              <div className="mbf-content">
                <MessageBoxHomePage setTitle={setTitle} /> 
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </motion.div>
    </div>
  );
};

export default MessageBoxFrame;
