import React from 'react';
import './PreviewComponent.css';

const PreviewComponent = () => {
  const messageBoxSrc =
    process.env.NODE_ENV === 'development'
      ? 'http://localhost:3000/messageboxapp'
      : 'https://fraba.germanywestcentral.cloudapp.azure.com/messageboxapp';

  return (
    <div className="m-pc-container">
      <div className="m-pc-outer-frame">
        <iframe
          title="MessageBoxApp"
          className="m-pc-inner-frame"
          src={messageBoxSrc}
        ></iframe>
      </div>
    </div>
  );
};

export default PreviewComponent;
