import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { useSelector } from 'react-redux';
import translations from './translations';
import './MessageBoxPreInfoCollector.css';
import MessageBoxSupport from './MessageBoxSupport'; // 引入 MessageBoxSupport

const MessageBoxPreInfoCollector = ({ name, selectedOption, ipCity, ipCountry, ipLanguage, selectedLanguage, onContinue }) => {
  const currentLanguage = useSelector((state) => state.msgboxLanguage);
  const t = translations[currentLanguage];

  const [companyName, setCompanyName] = useState('');
  const [industry, setIndustry] = useState('');
  const [countryCity, setCountryCity] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [showSupportBox, setShowSupportBox] = useState(false);

  const handleContinueClick = () => {
    if (selectedOption === 'support') {
      setShowSupportBox(true); // 设置显示 MessageBoxSupport
    } else {
      onContinue({ companyName, industry, countryCity, zipCode });
    }
  };

  return (
    <motion.div
      className="mbpc-container"
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      {!showSupportBox ? (
        <>
          <div className="mbpc-content">
            <div className="mbpc-title">
              {t['preinfo-collector-title']}
            </div>
            <div className="mbpc-form">
              <div className="mbpc-form-group">
                <label htmlFor="companyName">{t['preinfo-collector-companyName']}</label>
                <input
                  type="text"
                  id="companyName"
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
                  required
                />
              </div>
              <div className="mbpc-form-group">
                <label htmlFor="industry">{t['preinfo-collector-industry']}</label>
                <input
                  type="text"
                  id="industry"
                  value={industry}
                  onChange={(e) => setIndustry(e.target.value)}
                  required
                />
              </div>
              <div className="mbpc-form-group">
                <label htmlFor="countryCity">{t['preinfo-collector-countryCity']}</label>
                <input
                  type="text"
                  id="countryCity"
                  value={countryCity}
                  onChange={(e) => setCountryCity(e.target.value)}
                  required
                />
              </div>
              <div className="mbpc-form-group">
                <label htmlFor="zipCode">{t['preinfo-collector-zipCode']}</label>
                <input
                  type="text"
                  id="zipCode"
                  value={zipCode}
                  onChange={(e) => setZipCode(e.target.value)}
                  required
                />
              </div>
            </div>
          </div>
          <div className="mbpc-bottom">
            <button className="mbpc-continue-btn" onClick={handleContinueClick}>
              {t['preinfo-collector-continue']}
            </button>
          </div>
        </>
      ) : (
        <MessageBoxSupport
          name={name}
          selectedOption={selectedOption}
          ipCity={ipCity}
          ipCountry={ipCountry}
          ipLanguage={ipLanguage}
          selectedLanguage={selectedLanguage}
        />
      )}
    </motion.div>
  );
};

export default MessageBoxPreInfoCollector;
