
const ComposeMailAttachments = ({ attachments, setAttachments }) => {

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    const readerPromises = files.map(file => new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve({
        name: file.name,
        content: reader.result.split(',')[1],
        contentType: file.type,
        isNew: true
      });
      reader.onerror = reject;
      reader.readAsDataURL(file);
    }));

    Promise.all(readerPromises).then(results => {
      setAttachments([...attachments, ...results]);
    }).catch(error => {
      console.error('Failed to read files:', error);
    });
  };

  const handleRemoveAttachment = (index) => {
    const updatedAttachments = attachments.filter((_, i) => i !== index);
    setAttachments(updatedAttachments);
  };

  return {
    handleFileChange,
    handleRemoveAttachment,
  };
};

export default ComposeMailAttachments;
