import React, { useState, useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import './EmailDetailPersonnel.css';

const EmailDetailPersonnel = ({ from, toRecipients, ccRecipients }) => {
  const [expanded, setExpanded] = useState(false);
  const [needsExpand, setNeedsExpand] = useState(false);
  const fromRef = useRef(null);
  const toRef = useRef(null);
  const ccRef = useRef(null);

  useEffect(() => {
    const checkOverflow = () => {
      const isOverflowing = fromRef.current.scrollWidth > fromRef.current.clientWidth ||
                            toRef.current.scrollWidth > toRef.current.clientWidth ||
                            (ccRef.current && ccRef.current.scrollWidth > ccRef.current.clientWidth);
      setNeedsExpand(isOverflowing);
    };

    checkOverflow();
    window.addEventListener('resize', checkOverflow);
    return () => window.removeEventListener('resize', checkOverflow);
  }, [from, toRecipients, ccRecipients]);

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  return (
    <div className="edp-container">
      <div className={`edp-email-fields ${expanded ? 'expanded' : ''}`}>
        <div className="edp-email-field" style={{ flex: expanded ? '1 1 auto' : (ccRecipients && ccRecipients.length > 0 ? '1 1 33%' : '1 1 50%') }} ref={fromRef}>
          <strong>From: </strong> {from?.emailAddress?.name || 'Unknown Sender'}
        </div>
        <div className="edp-email-field" style={{ flex: expanded ? '1 1 auto' : (ccRecipients && ccRecipients.length > 0 ? '1 1 33%' : '1 1 50%') }} ref={toRef}>
          <strong>To: </strong> 
          <span className="edp-email-recipients">
            {toRecipients?.map(recipient => recipient?.emailAddress?.name).join(', ') || 'Unknown Recipients'}
          </span>
        </div>
        {ccRecipients && ccRecipients.length > 0 && (
          <div className="edp-email-field" style={{ flex: expanded ? '1 1 auto' : '1 1 33%' }} ref={ccRef}>
            <strong>CC: </strong> 
            <span className="edp-email-recipients">
              {ccRecipients?.map(cc => cc?.emailAddress?.name).join(', ') || 'Unknown CC Recipients'}
            </span>
          </div>
        )}
      </div>
      <div className="edp-toggle-icon-container">
        {needsExpand && (
          <FontAwesomeIcon
            icon={expanded ? faChevronUp : faChevronDown}
            className="edp-toggle-icon"
            onClick={toggleExpand}
          />
        )}
      </div>
    </div>
  );
};

export default EmailDetailPersonnel;
