import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperclip, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import './EmailBasicTag.css';

const EmailBasicTag = ({ hasAttachments, isRead, importance, categories }) => {
  return (
    <div className="ebt-email-basic-tag">
      {!isRead && (
        <span className="ebt-email-unread">UNREAD</span>
      )}
      {hasAttachments && (
        <FontAwesomeIcon icon={faPaperclip} title="Attachment" className="ebt-email-icon ebt-attachment-icon" />
      )}
      {importance === 'high' && (
        <FontAwesomeIcon icon={faExclamationCircle} title="High Importance" className="ebt-email-icon ebt-importance-icon" />
      )}
      {categories && categories.length > 0 && (
        <span className="ebt-email-categories">
          {categories.join(', ')}
        </span>
      )}
    </div>
  );
};

export default EmailBasicTag;
