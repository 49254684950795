import React, { useState, useEffect, useCallback, useRef } from 'react';
import { motion } from 'framer-motion';
import './SeenByIndicator.css';

const SeenByIndicator = ({ abbreviations }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [expandedWidth, setExpandedWidth] = useState(null);
  const contentRef = useRef(null);

  useEffect(() => {
    if (contentRef.current) {
      const padding = 0; 
      setExpandedWidth(`${contentRef.current.scrollWidth + padding}px`);
    }
  }, [isExpanded]);

  const handleClickOrHover = useCallback(() => {
    setIsExpanded(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setIsExpanded(false);
  }, []);

  const numLights = abbreviations ? abbreviations.split(', ').length : 0;

  return (
    <div className="sbi-seen-by-indicators-wrapper">
      <motion.div
        className="sbi-seen-by-indicators"
        onClick={handleClickOrHover}
        onMouseEnter={handleClickOrHover}
        onMouseLeave={handleMouseLeave}
        initial={false}
        animate={{ width: isExpanded ? expandedWidth : '42px' }}
        transition={{ type: 'spring', stiffness: 250, damping: 30 }}
      >
        {isExpanded ? (
          <span ref={contentRef} className="sbi-seen-by-text">
            Seen by {abbreviations}
          </span>
        ) : (
          <>
            {[...Array(3)].map((_, index) => (
              <span 
                key={index} 
                className={`sbi-seen-by-indicator ${index < numLights ? 'sbi-seen' : ''}`}
              />
            ))}
          </>
        )}
      </motion.div>
    </div>
  );
};

export default SeenByIndicator;
