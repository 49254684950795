import { msalInstance } from './authConfig';
import axios from 'axios';
import store from '../../store'; // 导入 store

const setAccessTokenToBackend = async (accessToken) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/setAccessToken`, { accessToken });
        if (response.status !== 200) {
            throw new Error('Failed to set access token to backend');
        }
    } catch (error) {
        console.error('Error setting access token to backend:', error);
    }
};

// 新增函数：检查 username 和 email 是否变化并发送到后端
const sendUserDetailsToBackend = async (retryCount = 0) => {
    // 一开始等待 1 秒再执行
    await new Promise(resolve => setTimeout(resolve, 1000));

    const state = store.getState();
    const username = state.user.name;
    const email = state.user.email;

    // 检查 username 和 email 是否非空
    if (username && email) {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/setUserDetails`, {
                username,
                email,
            });
            if (response.status !== 200) {
                throw new Error('Failed to set user details to backend');
            }
        } catch (error) {
            console.error('Error setting user details to backend:', error);
        }
    } else {
        console.log('Username or email is null, skipping sending user details to backend.');

        if (retryCount < 3) { // 最多重试 3 次
            const delay = [500, 1000, 2000][retryCount]; // 重试延迟分别为 500ms, 1000ms, 2000ms
            console.log(`Retrying in ${delay} ms...`);
            setTimeout(() => sendUserDetailsToBackend(retryCount + 1), delay);
        } else {
            console.log('Max retries reached, not sending user details to backend.');
        }
    }
};

msalInstance.addEventCallback((event) => {
    if (event.eventType === 'msal:acquireTokenSuccess' && event.payload.accessToken) {
        setAccessTokenToBackend(event.payload.accessToken)
            .then(() => {
                // 等待 accessToken 发送完成后，再检查 username 和 email
                sendUserDetailsToBackend();
            });
    }
    if (event.eventType === 'msal:loginSuccess' && event.payload.accessToken) {
        setAccessTokenToBackend(event.payload.accessToken)
            .then(() => {
                // 等待 accessToken 发送完成后，再检查 username 和 email
                sendUserDetailsToBackend();
            });
    }
});

export { setAccessTokenToBackend };
