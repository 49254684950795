import { PublicClientApplication } from '@azure/msal-browser';

// MSAL 配置
export const msalConfig = {
    auth: {
        clientId: '67da7c7b-7297-4c56-adb5-cf6c2c20b59f', // 应用程序的客户端 ID
        authority: 'https://login.microsoftonline.com/77518de8-059e-445b-b16f-b3a338b3dfe1', // AAD 租户的 authority
        redirectUri: window.location.origin, // 重定向 URI
    },
    cache: {
        cacheLocation: 'localStorage', // 将认证信息存储在 localStorage 中
        storeAuthStateInCookie: true, // 在 Cookie 中也存储状态信息，用于跨会话保持状态
    },
};

// 初始化 MSAL 应用实例
export const msalInstance = new PublicClientApplication(msalConfig);

// 登录请求的配置，增加更多的 Microsoft Graph 权限
export const loginRequest = {
    scopes: [
        'openid',
        'profile',
        'User.Read',
        'Calendars.Read',
        'Calendars.Read.Shared',
        'Calendars.ReadBasic',
        'Calendars.ReadWrite',
        'Calendars.ReadWrite.Shared',
        'Contacts.Read',
        'Contacts.Read.Shared',
        'email',
        'Files.Read',
        'Files.Read.All',
        'Files.ReadWrite',
        'Files.ReadWrite.All',
        'Mail.Read',
        'Mail.Read.Shared',
        'Mail.ReadBasic',
        'Mail.ReadBasic.Shared',
        'Mail.ReadWrite',
        'Mail.ReadWrite.Shared',
        'Mail.Send',
        'Mail.Send.Shared',
        'MailboxFolder.Read.All',
        'MailboxFolder.ReadWrite.All',
        'MailboxItem.Read.All',
        'MailboxSettings.Read',
        'MailboxSettings.ReadWrite',
        'User.Read.All',
        'User.ReadWrite',
        'User.ReadWrite.All',
        'Team.Create',
        'Team.ReadBasic.All'
    ]
};
