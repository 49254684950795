import React from 'react';
import { useIsAuthenticated } from '@azure/msal-react';
import AppsPage from'../AppsPage';
import LoginPage from './LoginPage'; // Import the new LoginPage component

const HomePage = () => {
    const isAuthenticated = useIsAuthenticated();

    return (
        <div>
            {isAuthenticated ? (
                <AppsPage />
            ) : (
                <LoginPage />
            )}
        </div>
    );
};

export default HomePage;
