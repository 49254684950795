import React from 'react';
import { useMsal } from '@azure/msal-react';
import './LoginPage.css'; 

const LoginPage = () => {
    const { instance } = useMsal();

    const handleLogin = () => {
        instance.loginPopup().catch(e => {
            console.error(e);
        });
    };

    return (
        <div className="ms-login-page">
            <div className="ms-login-container">
                <div className="ms-login-header">
                    <h1>Support Portal 2.0</h1>
                </div>
                <div className="ms-login-body">
                    <button onClick={handleLogin} className="ms-login-button">Sign in with Microsoft</button>
                </div>
            </div>
        </div>
    );
};

export default LoginPage;
