import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { useSelector } from 'react-redux';
import translations from './translations';
import './MessageBoxEntry.css';
import MessageBoxPreInfoCollector from './MessageBoxPreInfoCollector';

const MessageBoxEntry = ({ name, ipCity, ipCountry, ipLanguage, selectedLanguage }) => {
  const currentLanguage = useSelector((state) => state.msgboxLanguage);
  const t = translations[currentLanguage];
  const [selectedOption, setSelectedOption] = useState(null);
  const [showPreInfoCollector, setShowPreInfoCollector] = useState(false);

  const welcomeMessage = t['entry-welcomeMessage'].replace('{name}', name);

  const descriptionTexts = {
    inquiry: t['entry-description-inquiry'],
    support: t['entry-description-support'],
    documentation: t['entry-description-documentation'],
    typekey: t['entry-description-typekey'],
  };

  // 判断是否为 RTL 语言
  const isRTL = ['ar', 'he'].includes(currentLanguage);

  const handleContinue = () => {
    setShowPreInfoCollector(true);
  };

  return (
    <motion.div
      className={`m-mbe-container ${isRTL ? 'rtl' : ''}`}
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      {!showPreInfoCollector ? (
        <>
          <div className="m-mbe-content">
            <div className="m-mbe-title">
              {selectedOption ? descriptionTexts[selectedOption] : welcomeMessage}
            </div>
            <motion.div className="m-mbe-options" layout>
              <button
                className={`m-mbe-option-btn ${selectedOption === 'inquiry' ? 'selected' : ''}`}
                onClick={() => setSelectedOption('inquiry')}
              >
                {t['entry-option-inquiry']}
              </button>
              <button
                className={`m-mbe-option-btn ${selectedOption === 'support' ? 'selected' : ''}`}
                onClick={() => setSelectedOption('support')}
              >
                {t['entry-option-support']}
              </button>
              <button
                className={`m-mbe-option-btn ${selectedOption === 'documentation' ? 'selected' : ''}`}
                onClick={() => setSelectedOption('documentation')}
              >
                {t['entry-option-documentation']}
              </button>
              <button
                className={`m-mbe-option-btn ${selectedOption === 'typekey' ? 'selected' : ''}`}
                onClick={() => setSelectedOption('typekey')}
              >
                {t['entry-option-typekey']}
              </button>
            </motion.div>
          </div>

          {selectedOption && (
            <div className="m-mbe-bottom">
              <button className="m-mbe-continue-btn" onClick={handleContinue}>
                {t['entry-continue']}
              </button>
            </div>
          )}
        </>
      ) : (
        <MessageBoxPreInfoCollector 
        name={name} 
        selectedOption={selectedOption} 
        ipCity={ipCity}
        ipCountry={ipCountry} 
        ipLanguage={ipLanguage}
        selectedLanguage={selectedLanguage}
        />
      )}
    </motion.div>
  );
};

export default MessageBoxEntry;
